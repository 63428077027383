import React, { useMemo, useState, useCallback, useRef } from 'react';
import { scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { LinePath, Line } from '@visx/shape';
import { extent } from 'd3-array';
import { Text } from '@visx/text';
import { useTooltip } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { NumberedModelRow } from '../../domain/NumberedModelRow';
import Legend from '../Legend';
import { Grid } from '@visx/grid';
import { RawDataItem } from '../../domain/RawDataItem';
import DownloadChartButton from '../DownloadChartButton';

interface OATChartProps {
  rawData: RawDataItem[]
  data: NumberedModelRow[];
  width: number;
  height: number;
}

const colors = ["#1B9E77", "#D95F02", "#7570B3", "#E7298A", "#66A61E", "#E6AB02", "#A6761D"];
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const getDayName = (isoDow: number): string => days[(isoDow - 1) % 7];

const OATChart: React.FC<OATChartProps> = ({ data, rawData, width, height }) => {
  const margin = { top: 40, right: 20, bottom: 80, left: 60 };
 
  const yMax = height - margin.top - margin.bottom;
  const xMax = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const [visibleDays, setVisibleDays] = useState<Set<number>>(new Set([1, 2, 3, 4, 5, 6, 7]));
  const [showExcluded, setShowExcluded] = useState(false);
  const [hoveredPoint, setHoveredPoint] = useState<NumberedModelRow | null>(null);
  const [xDomain, setXDomain] = useState<[number, number] | null>(null);
  const chartRef = useRef<SVGSVGElement>(null);
  const [zoomRect, setZoomRect] = useState<{ startX: number; endX: number } | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  } = useTooltip<NumberedModelRow>();

  // Memoize filtered data
  const filteredRawData = useMemo(() => 
    rawData.filter(d => visibleDays.has(d.mode) && (d.cause === 'Clean' || showExcluded)),
    [rawData, visibleDays, showExcluded]
  );

  // Memoize domains
  const domains = useMemo(() => {
    const xExtent = extent(filteredRawData, d => d.oat) as [number, number];
    const yExtent = extent(filteredRawData, d => d.rate) as [number, number];
    return { xExtent, yExtent };
  }, [filteredRawData]);

  const xScale = useMemo(
    () => scaleLinear<number>({
      domain: xDomain || domains.xExtent,
      range: [0, xMax],
      nice: true,
    }),
    [xMax, domains.xExtent, xDomain]
  );

  const yScale = useMemo(
    () => scaleLinear<number>({
      domain: domains.yExtent,
      range: [yMax, 0],
      nice: true,
    }),
    [yMax, domains.yExtent]
  );

  const processedData = useMemo(() => {
    // Pre-sort data by OAT for faster lookups
    const sorted = [...data].sort((a, b) => a.oat - b.oat);
    
    // Pre-compute min/max values
    const oatMin = sorted[0]?.oat ?? 0;
    const oatMax = sorted[sorted.length - 1]?.oat ?? 0;
    const oatRange = oatMax - oatMin;
    
    return {
      allData: sorted,
      oatMin,
      oatMax,
      oatRange
    };
  }, [data]);

  const visibleData = useMemo(() => {
    if (!processedData.allData.length) return [];
    return processedData.allData.filter(d => visibleDays.has(d.mode));
  }, [processedData.allData, visibleDays]);

  // Pre-calculate index lookup for faster point finding
  const lookupIndices = useMemo(() => {
    if (!processedData.allData.length) return new Map();
    
    const indices = new Map();
    const bucketSize = processedData.oatRange / 100; // Split into 100 buckets
    
    processedData.allData.forEach((point, index) => {
      const bucket = Math.floor((point.oat - processedData.oatMin) / bucketSize);
      if (!indices.has(bucket)) {
        indices.set(bucket, index);
      }
    });
    
    return indices;
  }, [processedData]);

  const findClosestPoint = useCallback((mouseX: number, marginLeft: number) => {
    if (!visibleData.length || !processedData.allData.length) return null;

    const xOAT = xScale.invert(mouseX - marginLeft);
    
    // Find the closest bucket
    const bucketSize = processedData.oatRange / 100;
    const bucket = Math.floor((xOAT - processedData.oatMin) / bucketSize);
    
    // Get starting index from lookup
    let startIndex = lookupIndices.get(bucket) ?? 0;
    startIndex = Math.max(0, startIndex - 5); // Look a few points before to ensure we don't miss anything
    
    let closestPoint = null;
    let minDistance = Infinity;
    
    // Only search visible points in a small window around the mouse position
    for (let i = startIndex; i < processedData.allData.length; i++) {
      const point = processedData.allData[i];
      if (!visibleDays.has(point.mode)) continue;
      
      const distance = Math.abs(point.oat - xOAT);
      
      // Early exit if we've moved too far past the point
      if (point.oat > xOAT && distance > minDistance) break;
      
      if (distance < minDistance) {
        minDistance = distance;
        closestPoint = point;
      }
    }
    
    return closestPoint;
  }, [visibleData, processedData, xScale, visibleDays]);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      if (!visibleData.length) return;
      
      const { x, y } = localPoint(event) || { x: 0, y: 0 };
      const closestPoint = findClosestPoint(x, margin.left);
      
      if (closestPoint) {
        setHoveredPoint(closestPoint);
        showTooltip({
          tooltipData: closestPoint,
          tooltipLeft: x,
          tooltipTop: y,
        });
      }
    },
    [showTooltip, findClosestPoint, margin.left, visibleData.length]
  );

  const handleMouseLeave = useCallback(() => {
    hideTooltip();
    setHoveredPoint(null);
  }, [hideTooltip]);

  const handleMouseDown = useCallback((event: React.MouseEvent<SVGElement>) => {
    const point = localPoint(event);
    if (point) {
      setZoomRect({ startX: point.x - margin.left, endX: point.x - margin.left });
      setIsDragging(true);
    }
  }, [margin.left]);

  const handleDragMove = useCallback((event: React.MouseEvent<SVGElement>) => {
    if (!isDragging) return;
    const point = localPoint(event);
    if (point) {
      setZoomRect(prev => prev ? { ...prev, endX: point.x - margin.left } : null);
    }
  }, [isDragging, margin.left]);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
    if (zoomRect) {
      const { startX, endX } = zoomRect;
      
      // Create a scale based on the original domain for zoom calculations
      const baseScale = scaleLinear<number>({
        domain: domains.xExtent,
        range: [0, xMax],
        nice: true
      });
      
      let x1 = baseScale.invert(Math.min(startX, endX));
      let x2 = baseScale.invert(Math.max(startX, endX));
      
      // Clamp to the actual data range
      x1 = Math.max(x1, domains.xExtent[0]);
      x2 = Math.min(x2, domains.xExtent[1]);
      
      setXDomain([x1, x2]);
    }
    setZoomRect(null);
  }, [zoomRect, domains.xExtent, xMax]);

  const resetZoom = useCallback(() => {
    setXDomain(null);
  }, []);

  const toggleExcluded = useCallback(() => {
    setShowExcluded(prev => !prev);
    setXDomain(null);
  }, []);

  const toggleDay = useCallback((isoDow: number) => {
    setVisibleDays(prev => {
      const newSet = new Set(prev);
      if (newSet.has(isoDow)) {
        newSet.delete(isoDow);
      } else {
        newSet.add(isoDow);
      }
      return newSet;
    });
    setXDomain(null);
  }, []);

  // Memoize SVG event handlers
  const svgEventHandlers = useMemo(() => ({
    onMouseMove: handleMouseMove,
    onMouseLeave: handleMouseLeave,
    onMouseDown: handleMouseDown,
    onMouseMoveCapture: handleDragMove,
    onMouseUp: handleDragEnd
  }), [handleMouseMove, handleMouseLeave, handleMouseDown, handleDragMove, handleDragEnd]);

  // Memoize excluded and clean data points
  const { excludedPoints, cleanPoints } = useMemo(() => ({
    excludedPoints: rawData.filter(d => d.cause !== 'Clean'),
    cleanPoints: rawData.filter(d => d.cause === 'Clean')
  }), [rawData]);

  const groupedData = useMemo(() => {
    const groups: { [key: number]: NumberedModelRow[] } = {};
    visibleData.forEach(row => {
      if (!groups[row.mode]) {
        groups[row.mode] = [];
      }
      groups[row.mode].push(row);
    });
    return Object.entries(groups)
      .map(([iso_dow, rows]) => ({
        iso_dow: parseInt(iso_dow),
        rows
      }));
  }, [visibleData]);

  return (
    <div style={{ position: 'relative' }}>
      <DownloadChartButton chartRef={chartRef} filename="oat-chart" />
      <button onClick={resetZoom} style={{ position: 'absolute', top: -10, right: 10 }}>
        Reset Zoom
      </button>
      <svg ref={chartRef} width={width} height={height}      
           {...svgEventHandlers}
      >

        <defs>
          <symbol id="andreas-cross-oat" >
            <line 
              x1="0" y1="0"
              x2="8" y2="8"
              strokeWidth={1.5}
            />
            <line
              x1="0" y1="8"
              x2="8" y2="0"
              strokeWidth={1.5}
            />
          </symbol>
        </defs>

        <Group left={margin.left} top={margin.top}>
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
            strokeOpacity={0.1}
          />

         

          {cleanPoints
            .filter(d => visibleDays.has(d.mode))
            .map((d, i) => (
              <circle
                key={`clean-${i}-${d.oat}-${d.rate}`}
                cx={xScale(d.oat)}
                cy={yScale(d.rate)}
                r={1}
                fill={colors[(d.mode - 1) % colors.length]}
                stroke={colors[(d.mode - 1) % colors.length]}
              />
            ))}

          {/*groupedData.map(({ iso_dow, rows }, groupIndex) => (
            rows.map((row, rowIndex) => (
              <LinePath<NumberedModelRow>
                key={`line-${groupIndex}-${rowIndex}`}
                data={rows}
                x={(d: NumberedModelRow) => xScale(d.oat)}
                y={(d: NumberedModelRow) => yScale(d.pred)}
                stroke={colors[(iso_dow - 1) % colors.length]}
                strokeWidth={0.1}
              />
            ))
          ))*/}
           
          
          <AxisLeft 
            scale={yScale} 
            left={xScale(0)}
            label="Power (kW)"
            numTicks={6}
            tickFormat={(value) => `${Math.round(+value)}`}
          />
          <AxisBottom 
            scale={xScale} 
            top={innerHeight} 
            label="OAT (°C)"
          />

          <Text
            angle={-90}
            width={yMax}
            y={-margin.left + 15}
            x={-yMax / 2}
            textAnchor="middle"
          >
            Power (kW)
          </Text>

        
          {tooltipData && tooltipLeft !== undefined && (
            <Line
              from={{ x: tooltipLeft - margin.left, y: 0 }}
              to={{ x: tooltipLeft - margin.left, y: yMax }}
              stroke="#999"
              strokeWidth={1}
              pointerEvents="none"
            />
          )}

          {/* Zoom rectangle */}
          {zoomRect && (
            <rect
              x={Math.min(zoomRect.startX, zoomRect.endX)}
              y={margin.top}
              width={Math.abs(zoomRect.endX - zoomRect.startX)}
              height={yMax}
              fill="rgba(0, 128, 255, 0.2)"
              stroke="blue"
              strokeWidth={1}
              strokeDasharray="4 4"
            />
          )}
            
             <g transform={`translate(0, ${yMax + 40})`}>
            <Legend
              days={days}
              colors={colors}
              visibleDays={visibleDays}
              toggleDay={toggleDay}
              showExcluded={showExcluded}
              toggleExcluded={toggleExcluded}
              width={xMax}
            />
          </g>
        </Group>
       
      </svg>
      {hoveredPoint && (
        <TooltipContent 
          point={hoveredPoint} 
          top={tooltipTop} 
          left={tooltipLeft} 
        />
      )}
    </div>
  );
};

// Memoized tooltip component
const TooltipContent = React.memo<{
  point: NumberedModelRow;
  top: number;
  left: number;
}>(({ point, top, left }) => (
  <div
    style={{
      position: 'absolute',
      top,
      left,
      transform: 'translate(-50%, -100%)',
      backgroundColor: 'rgba(255, 255, 255, 0.8)', 
      padding: '8px',
      borderRadius: '4px',
      boxShadow: '0 1px 10px rgba(0,0,0,0.1)',
      pointerEvents: 'none',
    }}
  >
    <table>
      <tbody>
        <tr><th style={{textAlign: 'left'}}>OAT</th><td style={{textAlign: 'right'}}>{point.oat?.toFixed(2)}</td><td style={{textAlign: 'left'}}>°C</td></tr>
        <tr><th style={{textAlign: 'left'}}>Rate</th><td style={{textAlign: 'right'}}>{point.rate?.toFixed(2)}</td><td style={{textAlign: 'left'}}>kW</td></tr>
        <tr><th style={{textAlign: 'left'}}>Expected</th><td style={{textAlign: 'right'}}>{point.pred?.toFixed(2)}</td><td style={{textAlign: 'left'}}>kW</td></tr>
        <tr><th style={{textAlign: 'left'}}>Day</th><td>{getDayName(point.mode)}</td></tr>
      </tbody>
    </table>
  </div>
));

export default OATChart;