import React, { useMemo } from 'react';
import { MeterReadingProfile } from '../../domain/MeterReadingProfile';
import { WeatherDay } from '../../domain/WeatherDay';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import { extent } from 'd3-array';

 
interface DayProfileProps {
  meterReadings: MeterReadingProfile;
  weatherData: WeatherDay;
  width?: number;
  height?: number;
  colors?: string[];
  onClick?: () => void;
}

const margin = { top: 0, right: 0, bottom: 0, left: 0 };

export const DayProfile: React.FC<DayProfileProps> = ({ 
  meterReadings, 
  weatherData,
  width = 400,
  height = 200,
  colors = ["#1B9E77", "#D95F02", "#7570B3", "#E7298A", "#66A61E", "#E6AB02", "#A6761D"],
  onClick,
}) => {
  // Get the first day's data
  const dayReadings = meterReadings.profile || [];

  // Create time labels (48 points for a day)
  const timeLabels = useMemo(() => 
    Array.from({ length: 48 }, (_, i) => {
      const hour = Math.floor(i / 2);
      const minute = i % 2 === 0 ? '00' : '30';
      return `${hour.toString().padStart(2, '0')}:${minute}`;
    }), []
  );

  // Dimensions
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // Scales
  const xScale = scaleLinear({
    domain: [0, 47],
    range: [0, xMax],
  });

  const yReadingsScale = scaleLinear({
    domain: extent(dayReadings, d => d) as [number, number],
    range: [yMax, 0],
    nice: true,
  });

 

  // Data points for the lines
  const readingsPoints = dayReadings.map((d, i) => ({
    x: xScale(i),
    y: yReadingsScale(d),
    value: d,
    time: timeLabels[i],
  }));

  

   

  return (
    <div style={{ position: 'relative' }}>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill="transparent" onClick={onClick} style={{ cursor: 'pointer' }} />
        <Group left={margin.left} top={margin.top}>
        
         

          {/* Readings line */}
          <LinePath
            data={readingsPoints}
            x={d => d.x}
            y={d => d.y}
            stroke={
             
              meterReadings.mode === 1 ? colors[0] :
              meterReadings.mode === 2 ? colors[1] :
              meterReadings.mode === 3 ? colors[2] :
              meterReadings.mode === 4 ? colors[3] :
              meterReadings.mode === 5 ? colors[4] :
              meterReadings.mode === 6 ? colors[5] :
              colors[6]
            }
            strokeWidth={1}
          />

        
          
          
         

        
        </Group>
      </svg>

     
    </div>
  );
};

export default DayProfile;