import axios from 'axios';
import { RawDataItem } from '../domain/RawDataItem';
import { ModeledData } from '../domain/ModeledData';
import { KServerFittedData } from '../domain/KServerFittedData';
import { numberEvents } from '../model/kintelligence/eventNumbering';
import { summarisedEvents } from '../model/kintelligence/eventSummary';
import { assignPentile, pentileProfiler, pentileDataPointCompact } from '../model/kfeature/pentileProfile';
import { EventFeatures } from '../domain/EventFeatures';
import { PentileDataPoint, PentileDataPointCompact } from '../domain/PentileDataPoint';
import { NumberedModelRow } from '../domain/NumberedModelRow';
import { MeterReadingProfile } from '../domain/MeterReadingProfile';
import { WeatherDay } from '../domain/WeatherDay';
import { nkmeansOrdered } from '../model/kfeature/kmeans';
import { linearRegression, calculateRMSE, polynomialRegression, exponentialRegression, powerRegression, cubicSplineRegression, naturalCubicSplineRegression } from './mathUtils';

export interface ProcessedMeterData {
  cleanedReadings: RawDataItem[];
  excludedReadings: RawDataItem[];
  modeledData: ModeledData[];
  modelWithEvents: NumberedModelRow[];
  eventSummary: any[];
  pentiledProfile: PentileDataPointCompact[];
  pentiledEventProfile: PentileDataPoint[];
  eventFeatures?: EventFeatures;
}

interface DayProcessor {
  day: number;
  data: ModeledData[];
}

export const modelDayData = async (rawData: ModeledData[], day: number, utility: string): Promise<ModeledData[]> => {
  if(!rawData.filter) { return []; }
  const dayData = rawData.filter(d => d.cause === 'Clean' && d.mode === day);
  if (dayData.length < 3) { return []; }
  try {
    const endpoint = utility ==='GAS' ? 'cobs_fit' : 'natural_fit';
    const response = await axios.post<KServerFittedData>(`${process.env.REACT_APP_R_API_URL}/${endpoint}`, {
      x: dayData.map(d => d.oat),
      y: dayData.map(d => d.rate)
    });
    
    return dayData.map((d, index) => ({
      ...d,
      pred: response.data.fitted[index],
      used: true,
      profile: d.profile,
      waste: d.rate - response.data.fitted[index]
    }));
  } catch (error) {
    console.error('Error in modelDayData:', error);
    // Fallback to spline or polynomial regression if API call fails
    try {
      const x = dayData.map(d => d.oat);
      const y = dayData.map(d => d.rate);
      
      // Try spline regression first
      try {
        const numKnots = Math.min(Math.max(3, Math.floor(x.length / 6)), 7);
        const splineFit = naturalCubicSplineRegression(x, y, numKnots);
        return dayData.map((d, index) => ({
          ...d,
          pred: splineFit.predicted[index],
          used: true,
          profile: d.profile,
          waste: d.rate - splineFit.predicted[index]
        }));
      } catch (splineError) {
        console.warn('Spline regression failed in fallback, trying polynomial:', splineError);
        
        // Try polynomial regression next
        try {
          const polyFit = polynomialRegression(x, y, 2);
          return dayData.map((d, index) => ({
            ...d,
            pred: polyFit.predicted[index],
            used: true,
            profile: d.profile,
            waste: d.rate - polyFit.predicted[index]
          }));
        } catch (polyError) {
          console.warn('Polynomial regression failed in fallback, trying linear:', polyError);
          
          // Fallback to linear if polynomial fails
          const linearFit = linearRegression(x, y);
          return dayData.map((d, index) => ({
            ...d,
            pred: linearFit.predicted[index],
            used: true,
            profile: d.profile,
            waste: d.rate - linearFit.predicted[index]
          }));
        }
      }
    } catch (fallbackError) {
      console.error('All regression methods failed:', fallbackError);
      return [];
    }
  }
};

export const modelData = async (rawData: ModeledData[]): Promise<ModeledData[]> => {
  if(!rawData.filter) { return []; }
  try {
    const response = await axios.post<KServerFittedData>(`${process.env.REACT_APP_R_API_URL}/cobs_fit`, {
      x: rawData.map(d => d.oat),
      y: rawData.map(d => d.rate)
    });

    return rawData.map((d, index) => ({
      ...d,
      pred: response.data.fitted[index],
      used: true,
      waste: d.rate - response.data.fitted[index]
    }));
  } catch (error) {
    return [];
  }
};

// Helper function to normalize an array of numbers to [0,1] range
const normalize = (data: number[]): number[] => {
  const min = Math.min(...data);
  const max = Math.max(...data);
  return data.map(x => (x - min) / (max - min || 1));
};

// normalize and cluster readings into 2 clusters. return difference between the two centers
const calculateSharpness = (readings: number[] | undefined): number => {
  // Return 0 if readings is undefined or empty
  if (!readings || readings.length === 0) {
    return 0;
  }
  
  try {
    const normalizedReadings = normalize(readings);
    const clusters = nkmeansOrdered(normalizedReadings, 2);
    return clusters.centers[1] - clusters.centers[0];
  } catch (error) {
    console.error('Error calculating sharpness:', error);
    return 0;
  }
}

const calculateClusterError = (readings: number[] | undefined): number => {
  // Return 0 if readings is undefined or empty
  if (!readings || readings.length === 0) {
    return 0;
  }
  
  try {
    const normalizedReadings = normalize(readings);
    const clusters = nkmeansOrdered(normalizedReadings, 2);
    // return sum of squares of differences between centers
    return clusters.withinss.reduce((sum, ss) => sum + ss, 0);
  } catch (error) {
    console.error('Error calculating cluster error:', error);
    return 0;
  }
}

// returns the sum of normalized readings of the cluster with the highest center
const calculatePowerHighCluster = (readings: number[] | undefined): number => {
  // Return 0 if readings is undefined or empty
  if (!readings || readings.length === 0) {
    return 0;
  }
  
  try {
    const normalizedReadings = normalize(readings);
    const clusters = nkmeansOrdered(normalizedReadings, 2);
    // return sum of squares of differences between centers
    return clusters.centers[1];
  } catch (error) {
    console.error('Error calculating cluster error:', error);
    return 0;
  }
}

const midRangeValues = (readings: number[] | undefined): number => {
  // Return 0 if readings is undefined or empty
  if (!readings || readings.length === 0) {
    return 0;
  }
  
  try {
    const normalizedReadings = normalize(readings);
    const clusters = nkmeansOrdered(normalizedReadings, 3);
    // return size of middle cluster
    return clusters.cluster.filter(c => c === 1).length;
  } catch (error) {
    console.error('Error calculating midRangeValues:', error);
    return 0;
  }
}
function mean(series: number[]): number {
  return series.reduce((sum, val) => sum + val, 0) / series.length;
}

function standardDeviation(series: number[]): number {
  const avg = mean(series);
  return Math.sqrt(series.reduce((sum, val) => sum + (val - avg) ** 2, 0) / series.length);
}

function minMaxDiff(series: number[]): number {
  return Math.max(...series) - Math.min(...series);
}

function peakToMeanRatio(series: number[]): number {
  return Math.max(...series) / mean(series);
}

function autocorrelation(series: number[], lag: number): number {
  if (lag >= series.length) return 0;
  const avg = mean(series);
  const numerator = series.slice(0, -lag).reduce((sum, val, i) => sum + (val - avg) * (series[i + lag] - avg), 0);
  const denominator = series.reduce((sum, val) => sum + (val - avg) ** 2, 0);
  return numerator / denominator;
}

function entropy(series: number[]): number {
  const sum = series.reduce((acc, val) => acc + val, 0);
  const probabilities = series.map(val => val / sum);
  return -probabilities.reduce((acc, p) => (p > 0 ? acc + p * Math.log2(p) : acc), 0);
}

function fourierTransformMagnitude(series: number[]): number[] {
  const N = series.length;
  const magnitudes: number[] = [];
  for (let k = 0; k < N; k++) {
      let real = 0, imag = 0;
      for (let n = 0; n < N; n++) {
          const angle = (2 * Math.PI * k * n) / N;
          real += series[n] * Math.cos(angle);
          imag -= series[n] * Math.sin(angle);
      }
      magnitudes.push(Math.sqrt(real ** 2 + imag ** 2));
  }
  return magnitudes;
}

function calculateMaxIncline(profile: number[]): number {
  const incline = [];
  for (let i = 1; i < profile.length; i++) {
      incline.push(profile[i] - profile[i - 1]);
  }
  return Math.max(...incline);
}

function calculateMaxDecline(profile: number[]): number {
  const decline = [];
  for (let i = 1; i < profile.length; i++) {
      decline.push(profile[i - 1] - profile[i]);
  }
  return Math.max(...decline);
}

export const FEATURE_INDICES = {
  MAX_INCLINE: 0,
  MAX_DECLINE: 1,
  MAX_INDEX: 2,
  MIN_INDEX: 3,
  MAX_INCLINE_INDEX: 4,
  MAX_DECLINE_INDEX: 5,
  SHARPNESS: 6,
  NORMALIZED_RATE: 7,
  MID_RANGE: 8,
  START_STOP_DIFF: 9,
  CLUSTER_ERROR: 10,
  POWER_HIGH_CLUSTER: 11,
  RATES: 12,
  OAT: 13,
  MODE_CHANGES: 14,
  ON_OFF_DIFF: 15,
  MEAN_RATES: 16,
  STD_RATES: 17,
  MIN_MAX_DIFF: 18,
  PEAK_TO_MEAN: 19,
  AUTOCORRELATION: 20,
  ENTROPY: 21,
  FFT_MAGNITUDES: 22,
  ISO_DOW: 23,
  CALENDAR_WEEK: 24,
  RATE_BY_OAT: 25
};

export const fetchDayProfiles = async (meterkey: string, days: number = 1000): Promise<MeterReadingProfile[] | undefined> => {
  try {
    const response = await axios.get<MeterReadingProfile[]>(`${process.env.REACT_APP_API_URL}readingdays/profiles/${meterkey}/${days}`);

    return response.data;
  } catch (error) {
    console.error('Error fetching profiles:', error);
    return undefined;
  }
};

export const fetchWeatherDays = async (meterkey: string, days: number = 1000): Promise<WeatherDay[] | undefined> => {
  try {
    const response = await axios.get<WeatherDay[]>(`${process.env.REACT_APP_API_URL}weatherdays/${meterkey}/${days}`);

    return response.data;
  } catch (error) {
    console.error('Error fetching weather days:', error);
    return undefined;
  }
};

export interface RawMeterData {
  cleanedReadings: RawDataItem[];
  excludedReadings: RawDataItem[];
  eventFeatures?: EventFeatures;
}

export const fetchMeterData = async (meterkey: string): Promise<RawMeterData> => {
  // Fetch both cleaned readings and event features in parallel
  const [cleanedResponse] = await Promise.all([
    axios.get(`${process.env.REACT_APP_API_URL}/cleaned_readings`, {
      params: { meter_key: meterkey }
    })
  ]);

  const rawData: RawDataItem[] = cleanedResponse.data;
  const cleanedReadings = rawData;
  const excludedReadings = rawData.filter?rawData.filter(d => d.cause !== 'Clean'):[];

  return {
    cleanedReadings,
    excludedReadings
  };
};



export const fetchMeterDataSimple = async (meterkey: string): Promise<RawMeterData> => {
  // Fetch both cleaned readings and event features in parallel
  const [cleanedResponse] = await Promise.all([
    axios.get(`${process.env.REACT_APP_API_URL}/cleaned_readings`, {
      params: { meter_key: meterkey }
    }),
    
  ]);

  const rawData: RawDataItem[] = cleanedResponse.data;
  const cleanedReadings = rawData;
  const excludedReadings = rawData.filter?rawData.filter(d => d.cause !== 'Clean'):[];

  return {
    cleanedReadings,
    excludedReadings
    
  };
};

export const convertToModeledData = (item: RawDataItem): ModeledData => {
  return {
    ...item,
    pred: 0,
    used: false,
    waste: 0,
    normalizedRate: 0,
    maxIdx: 0,
    minIdx: 0,
    maxInclineIdx: 0,
    maxDeclineIdx: 0,
    modeChanges: 0
  };
};

export const processMeterData = async (rawData: RawDataItem[]): Promise<ProcessedMeterData> => {
  if(rawData.length < 300) {
    return {
      cleanedReadings: rawData,
      excludedReadings: [],
      modeledData: [],
      modelWithEvents: [],
      eventSummary: [],
      pentiledProfile: [],
      pentiledEventProfile: [],
      eventFeatures: undefined
    };
  } 
  const cleanedReadings = rawData;
  const excludedReadings = rawData.filter?rawData.filter(d => d.cause !== 'Clean'):[];

  // Convert RawDataItem[] to ModeledData[] with default values
  const initialModeledData = rawData.map(convertToModeledData);

  // Process each day in parallel
  const daysToProcess: number[] = [1, 2, 3, 4, 5, 6, 7];
  const processedDays = await Promise.all(
    daysToProcess.map(day => modelDayData(initialModeledData, day, 'GAS'))
  );

  // Create modeledData object
  const modeledData: ModeledData[] = processedDays.flat();


  // Process events
  const flatModeledData = processedDays.flat();
  const modelWithEvents = numberEvents(flatModeledData);
  const eventSummary = summarisedEvents(modelWithEvents);
  const latestEventNo = eventSummary.reduce((latest, current) => 
    current.event_no > latest.event_no ? current : latest
  ).event_no;

  // Process pentile data
  const pentiledModel = assignPentile(modelWithEvents.filter(d => d.event_no !== latestEventNo));
  const pentiledModelEvents = assignPentile(modelWithEvents.filter(d => d.event_no === latestEventNo));
  
  const pentiledProfile = pentileProfiler(pentiledModel, 48);
  const pentiledProfileEvents = pentileProfiler(pentiledModelEvents, 48);

  return {
    cleanedReadings,
    excludedReadings,
    modeledData,
    modelWithEvents,
    eventSummary,
    pentiledProfile: pentileDataPointCompact(pentiledProfile),
    pentiledEventProfile: pentiledProfileEvents,
    eventFeatures: undefined // This will need to be passed in if needed
  };
};

export const fetchAndProcessMeterData = async (meterkey: string): Promise<ProcessedMeterData> => {
  const rawData = await fetchMeterData(meterkey);
  const processedData = await processMeterData(rawData.cleanedReadings);
  return {
    ...processedData,
    eventFeatures: rawData.eventFeatures
  };
};

export const fetchAndProcessMeterData365 = async (meterkey: string): Promise<ProcessedMeterData> => {
  const rawData = await fetchMeterData(meterkey);
  // only process the latest 365 days
  const processedData = await processMeterData(rawData.cleanedReadings.filter(d => d.ts > new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString()));
  return {
    ...processedData,
    eventFeatures: rawData.eventFeatures
  };
};



export const processMeterDataSimple = async (rawData:RawMeterData): Promise<ProcessedMeterData> => {
  
  const processedData = await processMeterData(rawData.cleanedReadings);
  return processedData 
};