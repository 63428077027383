import React, { useState, useRef } from 'react';
import { Text } from '@visx/text';
import ModeSummary from './ModeSummary';
import { ModeledData } from '../domain/ModeledData';

interface ModeLegendProps {
  modes: string[];
  colors: string[];
  visibleModes: Set<number>;
  toggleMode: (mode: number) => void;
  showExcluded: boolean;
  toggleExcluded: () => void;
  width: number;
  modeCounts?: Map<number, number>; // Optional prop to show count of items in each mode
  data?: ModeledData[]; // Add data for the ModeSummary
}

const ModeLegend: React.FC<ModeLegendProps> = ({ 
  modes, 
  colors, 
  visibleModes, 
  toggleMode, 
  showExcluded, 
  toggleExcluded, 
  width,
  modeCounts,
  data 
}) => {
  // Track the currently hovered mode
  const [hoveredMode, setHoveredMode] = useState<number | null>(null);
  // Track hover position for popup positioning
  const [hoverPosition, setHoverPosition] = useState<{x: number, y: number} | null>(null);
  // Ref for the SVG element to get its position
  const svgRef = useRef<SVGSVGElement>(null);

  // Filter out modes with no data points if modeCounts is provided
  const filteredModes = modeCounts 
    ? modes.filter((_, index) => modeCounts.get(index + 1) && modeCounts.get(index + 1)! > 0)
    : modes;
  
  // Calculate the item width based on filtered modes
  const legendWidth = width * 0.9; // Adjust this value as needed
  const itemWidth = filteredModes.length > 0 ? legendWidth / filteredModes.length : legendWidth;

  // Mouse event handlers
  const handleMouseEnter = (mode: number, event: React.MouseEvent) => {
    if (svgRef.current && data) {
      const rect = svgRef.current.getBoundingClientRect();
      setHoveredMode(mode);
      
      // Calculate popup position - stay in viewport
      const viewportWidth = window.innerWidth;
      const mouseX = event.clientX;
      
      // If we're in the right half of screen, position to the left of cursor
      // otherwise position to the right
      const isRightHalf = mouseX > viewportWidth / 2;
      
      setHoverPosition({
        x: isRightHalf ? Math.max(0, event.clientX - rect.left - 420) : event.clientX - rect.left,
        y: event.clientY - rect.top + 20 // Offset to position below the cursor
      });
    }
  };

  const handleMouseLeave = () => {
    setHoveredMode(null);
    setHoverPosition(null);
  };

  return (
    <div style={{ position: 'relative' }}>
      <svg width={legendWidth} height={20} ref={svgRef}>
        {filteredModes.map((mode, index) => {
          const modeNumber = modes.indexOf(mode) + 1;
          const count = modeCounts ? modeCounts.get(modeNumber) || 0 : 0;
          
          return (
            <g 
              key={mode} 
              transform={`translate(${index * itemWidth}, 0)`}
              style={{ cursor: 'pointer' }}
              onClick={() => toggleMode(modeNumber)}
              onMouseEnter={(e) => handleMouseEnter(modeNumber, e)}
              onMouseLeave={handleMouseLeave}
            >
              <g transform={`translate(10, 10)`}>
                <circle
                  r={4}
                  fill={colors[(modeNumber - 1) % colors.length]}
                  opacity={visibleModes.has(modeNumber) ? 1 : 0.3}
                />
              </g>
              <Text
                x={20}
                y={15}
                fontSize={10}
                textAnchor="start"
                opacity={visibleModes.has(modeNumber) ? 1 : 0.3}
              >
                {`${mode}${modeCounts && count > 0 ? ` (${count})` : ''}`}
              </Text>
            </g>
          );
        })}
        <g 
          style={{ cursor: 'pointer' }}
          onClick={toggleExcluded}
          opacity={showExcluded ? 1 : 0.3}
        >
        </g>
      </svg>
      
      {/* Render the ModeSummary as a popup when a mode is hovered */}
      {hoveredMode !== null && hoverPosition !== null && data && (
        <div style={{
          position: 'absolute',
          top: hoverPosition.y,
          left: hoverPosition.x,
          zIndex: 1000,
          maxWidth: '600px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
          display: data.some(item => item.mode === hoveredMode) ? 'block' : 'none'
        }}>
          <ModeSummary 
            mode={hoveredMode}
            data={data}
            color={colors[(hoveredMode - 1) % colors.length]}
          />
        </div>
      )}
    </div>
  );
};

export default ModeLegend;