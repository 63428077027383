import { Matrix, SingularValueDecomposition, LU } from 'ml-matrix';

// Calculate the Moore-Penrose pseudoinverse of a matrix
export function pseudoInverse(matrix: Matrix): Matrix {
  const svd = new SingularValueDecomposition(matrix);
  const U = svd.leftSingularVectors;
  const S = svd.diagonal;
  const V = svd.rightSingularVectors;
  
  // Create diagonal matrix of reciprocals of singular values
  const SPlus = new Matrix(S.length, S.length);
  for (let i = 0; i < S.length; i++) {
    SPlus.set(i, i, S[i] !== 0 ? 1 / S[i] : 0);
  }
  
  // Calculate pseudoinverse: V * S+ * U'
  return V.mmul(SPlus).mmul(U.transpose());
}

function matrixDeterminant(matrix: Matrix): number {
  const lu = new LU(matrix);
  let det = lu.determinant;
  
  // Adjust sign based on number of row swaps
  det *= lu.pivotPermutationVector.reduce((acc: number, val: any, idx: any) => acc * (val === idx ? 1 : -1), 1);
  
  return det;
}

export function multivariateNormalPDF(x: number[], mean: number[], covariance: Matrix): number {
  const k = x.length;
  const diff = new Matrix([x.map((val, i) => val - mean[i])]);
  
  try {
    const invCov = pseudoInverse(covariance);
    const detCov = matrixDeterminant(covariance);
    
    if (detCov <= 0) {
      return 0;
    }
    
    const exponent = -0.5 * diff.mmul(invCov).mmul(diff.transpose()).get(0, 0);
    const coefficient = 1 / Math.sqrt(Math.pow(2 * Math.PI, k) * detCov);
    
    return coefficient * Math.exp(exponent);
  } catch (error) {
    console.error('Error calculating multivariate normal PDF:', error);
    return 0;
  }
}

export function argmax(matrix: Matrix, axis: 'row' | 'column'): number[] {
  if (axis === 'row') {
    return matrix.to2DArray().map(row => {
      let maxIndex = 0;
      let maxValue = row[0];
      for (let i = 1; i < row.length; i++) {
        if (row[i] > maxValue) {
          maxValue = row[i];
          maxIndex = i;
        }
      }
      return maxIndex;
    });
  } else {
    const cols = matrix.columns;
    const result: number[] = [];
    for (let j = 0; j < cols; j++) {
      let maxIndex = 0;
      let maxValue = matrix.get(0, j);
      for (let i = 1; i < matrix.rows; i++) {
        if (matrix.get(i, j) > maxValue) {
          maxValue = matrix.get(i, j);
          maxIndex = i;
        }
      }
      result.push(maxIndex);
    }
    return result;
  }
}

export function linearRegression(x: number[], y: number[]): { slope: number; intercept: number; predicted: number[]; r2: number } {
  if (x.length !== y.length || x.length === 0) {
    throw new Error('Input arrays must have the same length and cannot be empty');
  }

  // Calculate means
  const meanX = x.reduce((sum, val) => sum + val, 0) / x.length;
  const meanY = y.reduce((sum, val) => sum + val, 0) / y.length;

  // Calculate slope
  let numerator = 0;
  let denominator = 0;
  for (let i = 0; i < x.length; i++) {
    numerator += (x[i] - meanX) * (y[i] - meanY);
    denominator += Math.pow(x[i] - meanX, 2);
  }
  
  const slope = denominator !== 0 ? numerator / denominator : 0;
  const intercept = meanY - slope * meanX;

  // Calculate predicted values
  const predicted = x.map(xi => slope * xi + intercept);

  // Calculate R-squared (coefficient of determination)
  const totalSumOfSquares = y.reduce((sum, yi) => sum + Math.pow(yi - meanY, 2), 0);
  const residualSumOfSquares = y.reduce((sum, yi, i) => sum + Math.pow(yi - predicted[i], 2), 0);
  const r2 = 1 - (residualSumOfSquares / totalSumOfSquares);

  return { slope, intercept, predicted, r2 };
}

// Calculate root mean squared error (RMSE) for model evaluation
export function calculateRMSE(observed: number[], predicted: number[]): number {
  if (observed.length !== predicted.length || observed.length === 0) {
    throw new Error('Input arrays must have the same length and cannot be empty');
  }
  
  const sumSquaredErrors = observed.reduce((sum, val, index) => 
    sum + Math.pow(val - predicted[index], 2), 0);
  
  return Math.sqrt(sumSquaredErrors / observed.length);
}

/**
 * Performs polynomial regression on input data.
 * @param x - Independent variable values
 * @param y - Dependent variable values
 * @param degree - Degree of the polynomial (default: 2)
 * @returns Object containing coefficients, predicted values, and r-squared
 */
export function polynomialRegression(x: number[], y: number[], degree: number = 2): { 
  coefficients: number[]; 
  predicted: number[]; 
  r2: number 
} {
  if (x.length !== y.length || x.length === 0) {
    throw new Error('Input arrays must have the same length and cannot be empty');
  }
  
  if (degree < 1) {
    throw new Error('Polynomial degree must be at least 1');
  }

  // Create design matrix X where each column is x raised to powers from 0 to degree
  const X = new Matrix(x.length, degree + 1);
  for (let i = 0; i < x.length; i++) {
    for (let j = 0; j <= degree; j++) {
      X.set(i, j, Math.pow(x[i], j));
    }
  }
  
  // Create Y matrix from the dependent variable
  const Y = Matrix.columnVector(y);
  
  // Calculate coefficients: (X'X)^-1 X'Y
  const XtX = X.transpose().mmul(X);
  const XtY = X.transpose().mmul(Y);
  
  // Use pseudoInverse to handle potential singularity
  const coefficients = pseudoInverse(XtX).mmul(XtY).to1DArray();
  
  // Calculate predicted values
  const predicted = x.map(xi => {
    let result = 0;
    for (let j = 0; j <= degree; j++) {
      result += coefficients[j] * Math.pow(xi, j);
    }
    return result;
  });
  
  // Calculate R-squared (coefficient of determination)
  const meanY = y.reduce((sum, yi) => sum + yi, 0) / y.length;
  const totalSumOfSquares = y.reduce((sum, yi) => sum + Math.pow(yi - meanY, 2), 0);
  const residualSumOfSquares = y.reduce((sum, yi, i) => sum + Math.pow(yi - predicted[i], 2), 0);
  const r2 = 1 - (residualSumOfSquares / totalSumOfSquares);
  
  return { coefficients, predicted, r2 };
}

/**
 * Performs cubic spline regression on input data
 * @param x - Independent variable values (should be sorted)
 * @param y - Dependent variable values
 * @param numKnots - Number of knots to use (default: 4)
 * @returns Object containing predicted values and r-squared
 */
export function cubicSplineRegression(x: number[], y: number[], numKnots: number = 4): {
  predicted: number[];
  r2: number;
} {
  if (x.length !== y.length || x.length === 0) {
    throw new Error('Input arrays must have the same length and cannot be empty');
  }
  
  if (numKnots < 2) {
    throw new Error('Number of knots must be at least 2');
  }
  
  if (numKnots >= x.length) {
    numKnots = Math.max(2, Math.floor(x.length / 2));
    console.warn(`Too many knots specified. Reducing to ${numKnots}`);
  }
  
  // Sort x values and reorder y accordingly
  const indices = x.map((_, i) => i).sort((a, b) => x[a] - x[b]);
  const sortedX = indices.map(i => x[i]);
  const sortedY = indices.map(i => y[i]);
  
  // Generate knot positions (including endpoints)
  const min = Math.min(...sortedX);
  const max = Math.max(...sortedX);
  const range = max - min;
  
  // Create knots at equally spaced intervals
  const knots: number[] = [];
  for (let i = 0; i < numKnots; i++) {
    knots.push(min + (i / (numKnots - 1)) * range);
  }
  
  // Create design matrix for cubic spline
  const X = new Matrix(sortedX.length, numKnots + 2); // +2 for the intercept and linear term
  
  // First column is all 1s (intercept)
  for (let i = 0; i < sortedX.length; i++) {
    X.set(i, 0, 1);
  }
  
  // Second column is the x value (linear term)
  for (let i = 0; i < sortedX.length; i++) {
    X.set(i, 1, sortedX[i]);
  }
  
  // Remaining columns are for the basis functions at each knot
  for (let j = 0; j < numKnots; j++) {
    for (let i = 0; i < sortedX.length; i++) {
      const u = Math.max(0, sortedX[i] - knots[j]);
      X.set(i, j + 2, Math.pow(u, 3));
    }
  }
  
  // Create Y matrix from the dependent variable
  const Y = Matrix.columnVector(sortedY);
  
  // Calculate coefficients using pseudoinverse
  const XtX = X.transpose().mmul(X);
  const XtY = X.transpose().mmul(Y);
  const coefficients = pseudoInverse(XtX).mmul(XtY).to1DArray();
  
  // Calculate predicted values for original data points (unsorted)
  const predicted: number[] = new Array(x.length);
  
  for (let idx = 0; idx < x.length; idx++) {
    const xi = x[idx];
    
    // Start with intercept and linear term
    let yi = coefficients[0] + coefficients[1] * xi;
    
    // Add contributions from each basis function
    for (let j = 0; j < numKnots; j++) {
      const u = Math.max(0, xi - knots[j]);
      yi += coefficients[j + 2] * Math.pow(u, 3);
    }
    
    predicted[idx] = yi;
  }
  
  // Calculate R-squared
  const meanY = y.reduce((sum, yi) => sum + yi, 0) / y.length;
  const totalSumOfSquares = y.reduce((sum, yi) => sum + Math.pow(yi - meanY, 2), 0);
  const residualSumOfSquares = y.reduce((sum, yi, i) => sum + Math.pow(yi - predicted[i], 2), 0);
  const r2 = 1 - (residualSumOfSquares / totalSumOfSquares);
  
  return { predicted, r2 };
}

/**
 * Natural cubic spline regression that enforces smoothness constraints
 * This implementation adds smoothness constraints by ensuring continuous 
 * second derivatives at knot points
 * @param x - Independent variable values
 * @param y - Dependent variable values
 * @param numKnots - Number of interior knots (excluding endpoints)
 */
export function naturalCubicSplineRegression(x: number[], y: number[], numKnots: number = 3): {
  predicted: number[];
  r2: number;
} {
  if (x.length !== y.length || x.length === 0) {
    throw new Error('Input arrays must have the same length and cannot be empty');
  }
  
  if (numKnots < 1) {
    throw new Error('Number of interior knots must be at least 1');
  }
  
  const n = x.length;
  
  // Sort data
  const indices = x.map((_, i) => i).sort((a, b) => x[a] - x[b]);
  const sortedX = indices.map(i => x[i]);
  const sortedY = indices.map(i => y[i]);
  
  // Create knot positions (interior knots plus endpoints)
  const min = Math.min(...sortedX);
  const max = Math.max(...sortedX);
  const range = max - min;
  
  // Create knots: endpoints plus interior knots
  const knots: number[] = [min];
  for (let i = 1; i <= numKnots; i++) {
    knots.push(min + (i / (numKnots + 1)) * range);
  }
  knots.push(max);
  
  // Total knots including endpoints
  const totalKnots = numKnots + 2;
  
  // Create basis functions for natural cubic spline
  // For each knot k, define N_k(x) as a basis function
  const basisFunctions = (xi: number): number[] => {
    const result: number[] = [1, xi]; // Constant and linear terms
    
    // Add basis functions for each knot pair
    for (let k = 0; k < totalKnots - 2; k++) {
      const dk = knots[k + 1] - knots[k];
      const dk1 = knots[k + 2] - knots[k + 1];
      
      // Define the basis function for this knot
      let term = 0;
      
      // Calculate the cubic basis term
      if (xi > knots[k + 1]) {
        term += Math.pow(xi - knots[k + 1], 3) / (dk1 * (dk + dk1));
      }
      
      if (xi > knots[k]) {
        term -= Math.pow(xi - knots[k], 3) / (dk * (dk + dk1));
      }
      
      result.push(term);
    }
    
    return result;
  };
  
  // Create design matrix
  const X = new Matrix(n, 2 + totalKnots - 2); // Constant, linear, and basis terms
  
  for (let i = 0; i < n; i++) {
    const basis = basisFunctions(sortedX[i]);
    for (let j = 0; j < basis.length; j++) {
      X.set(i, j, basis[j]);
    }
  }
  
  // Create Y vector
  const Y = Matrix.columnVector(sortedY);
  
  // Calculate coefficients
  const XtX = X.transpose().mmul(X);
  const XtY = X.transpose().mmul(Y);
  const coefficients = pseudoInverse(XtX).mmul(XtY).to1DArray();
  
  // Predict values for original (unsorted) data
  const predicted: number[] = new Array(n);
  
  for (let idx = 0; idx < n; idx++) {
    const xi = x[idx];
    const basis = basisFunctions(xi);
    
    let yi = 0;
    for (let j = 0; j < basis.length; j++) {
      yi += coefficients[j] * basis[j];
    }
    
    predicted[idx] = yi;
  }
  
  // Calculate R-squared
  const meanY = y.reduce((sum, yi) => sum + yi, 0) / y.length;
  const totalSumOfSquares = y.reduce((sum, yi) => sum + Math.pow(yi - meanY, 2), 0);
  const residualSumOfSquares = y.reduce((sum, yi, i) => sum + Math.pow(yi - predicted[i], 2), 0);
  const r2 = 1 - (residualSumOfSquares / totalSumOfSquares);
  
  return { predicted, r2 };
}

/**
 * Apply exponential regression to fit data to an exponential curve y = a * e^(b*x)
 * Returns linearized form: ln(y) = ln(a) + b*x
 * @param x - Independent variable values
 * @param y - Dependent variable values (must be all positive)
 */
export function exponentialRegression(x: number[], y: number[]): { 
  a: number;
  b: number;
  predicted: number[];
  r2: number 
} {
  if (x.length !== y.length || x.length === 0) {
    throw new Error('Input arrays must have the same length and cannot be empty');
  }
  
  // Check for positive values in y
  if (y.some(val => val <= 0)) {
    throw new Error('Exponential regression requires all y values to be positive');
  }
  
  // Take natural log of y values
  const lnY = y.map(yi => Math.log(yi));
  
  // Perform linear regression on x and ln(y)
  const { slope, intercept, r2 } = linearRegression(x, lnY);
  
  // Convert back from ln(a) to a
  const a = Math.exp(intercept);
  const b = slope;
  
  // Calculate predicted values using exponential function
  const predicted = x.map(xi => a * Math.exp(b * xi));
  
  return { a, b, predicted, r2 };
}

/**
 * Power regression to fit data to a power curve y = a * x^b
 * @param x - Independent variable values (must be all positive)
 * @param y - Dependent variable values (must be all positive)
 */
export function powerRegression(x: number[], y: number[]): { 
  a: number;
  b: number;
  predicted: number[];
  r2: number 
} {
  if (x.length !== y.length || x.length === 0) {
    throw new Error('Input arrays must have the same length and cannot be empty');
  }
  
  // Check for positive values in x and y
  if (x.some(val => val <= 0) || y.some(val => val <= 0)) {
    throw new Error('Power regression requires all x and y values to be positive');
  }
  
  // Take natural log of both x and y values
  const lnX = x.map(xi => Math.log(xi));
  const lnY = y.map(yi => Math.log(yi));
  
  // Perform linear regression on ln(x) and ln(y)
  const { slope, intercept, r2 } = linearRegression(lnX, lnY);
  
  // Convert back from ln(a) to a
  const a = Math.exp(intercept);
  const b = slope;
  
  // Calculate predicted values using power function
  const predicted = x.map(xi => a * Math.pow(xi, b));
  
  return { a, b, predicted, r2 };
}
