import React, { useMemo, useState } from 'react';
import DayProfile from './charts/DayProfile';
import { DayData } from '../domain/DayData';
import { ModeledData } from '../domain/ModeledData';

interface WeekProfilesProps {
  numberedModelData: ModeledData[];
  colors: string[];
  visibleModes?: Set<number>; 
}

// Type for our day cell
interface DayCell {
  date: Date;
  data: DayData | null;
}

export const WeekProfiles: React.FC<WeekProfilesProps> = ({ 
  numberedModelData, 
  colors,
  visibleModes = new Set() 
}) => {
  // State for tooltip
  const [tooltip, setTooltip] = useState<{
    visible: boolean;
    text: string;
    x: number;
    y: number;
  }>({
    visible: false,
    text: '',
    x: 0,
    y: 0
  });

  const filteredData = useMemo(() => {
    if (!visibleModes || visibleModes.size === 0) {
      return numberedModelData;
    }
    
    return numberedModelData.filter(item => visibleModes.has(item.mode));
  }, [numberedModelData, visibleModes]);

  // Helper function to get a consistent day of week (0 = Monday, ..., 6 = Sunday)
  // regardless of timezone or DST
  const getConsistentDayOfWeek = (date: Date): number => {
    // JavaScript getUTCDay() returns 0 for Sunday, 1 for Monday, etc.
    const utcDay = date.getUTCDay();
    // Convert to our format: 0 for Monday, ..., 6 for Sunday
    return utcDay === 0 ? 6 : utcDay - 1;
  };

  // Format date to YYYY-MM-DD in UTC
  const getUTCDateString = (date: Date): string => {
    return `${date.getUTCFullYear()}-${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}`;
  };

  const weeks = useMemo(() => {
    if (filteredData.length === 0) return [];

    // Sort data by timestamp
    const sortedData = [...filteredData].sort((a, b) => 
      new Date(a.ts).getTime() - new Date(b.ts).getTime()
    );

    // Create a map of date strings to data
    const dataByDate = new Map<string, DayData>();
    
    // Transform data to DayData format and populate the map
    sortedData.forEach(item => {
      const date = new Date(item.ts);
      const dateStr = getUTCDateString(date);
      
      dataByDate.set(dateStr, {
        excluded: false, 
        meterReading: {
          ts: item.ts,
          profile: item.profile || [],
          rate: item.rate || 0,
          normalizedRate: item.rate || 0, 
          maxIdx: 0, 
          minIdx: 0,
          maxInclineIdx: 0,
          maxDeclineIdx: 0,
          mode: item.mode
        },
        weatherData: {
          date: date,
          avgOat: item.oat,
          temperature: [item.oat], 
          uvIndex: [0], 
          windSpeed: [0]
        }
      });
    });

    // Get min and max dates from the data
    const minDate = new Date(sortedData[0].ts);
    const maxDate = new Date(sortedData[sortedData.length - 1].ts);
    
    // Adjust minDate to the Monday of its week (using UTC)
    const minDayOfWeek = getConsistentDayOfWeek(minDate);
    minDate.setUTCDate(minDate.getUTCDate() - minDayOfWeek);
    
    // Adjust maxDate to the Sunday of its week (using UTC)
    const maxDayOfWeek = getConsistentDayOfWeek(maxDate);
    maxDate.setUTCDate(maxDate.getUTCDate() + (6 - maxDayOfWeek));
    
    // Build weeks array
    const weeksArray: DayCell[][] = [];
    let currentWeek: DayCell[] = [];
    
    // Process all dates from the first Monday to the last Sunday
    const currentDate = new Date(minDate);
    while (currentDate <= maxDate) {
      const dayOfWeek = getConsistentDayOfWeek(currentDate);
      
      // Start a new week when we reach Monday (day 0)
      if (dayOfWeek === 0) {
        if (currentWeek.length > 0) {
          weeksArray.push(currentWeek);
        }
        currentWeek = [];
      }
      
      // Create a day cell
      const dateStr = getUTCDateString(currentDate);
      const dayCell: DayCell = {
        date: new Date(currentDate),
        data: dataByDate.get(dateStr) || null
      };
      
      // Add this day to the current week
      currentWeek.push(dayCell);
      
      // Move to the next day
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }
    
    // Add the last week if it has any days
    if (currentWeek.length > 0) {
      weeksArray.push(currentWeek);
    }
    
    // Verify all weeks have exactly 7 days
    weeksArray.forEach((week, index) => {
      if (week.length !== 7) {
        console.error(`Week ${index} has ${week.length} days instead of 7`);
      }
    });
    
    return weeksArray;
  }, [filteredData]);

  // Day names for column headers (Monday first, Sunday last)
  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const fullDayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  // Handle showing tooltip
  const handleMouseEnter = (event: React.MouseEvent, date: Date) => {
    const dayOfWeek = getConsistentDayOfWeek(date);
    const weekdayName = fullDayNames[dayOfWeek];
    
    const formattedDate = date.toLocaleDateString(undefined, { 
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric' 
    });
    
    const matchedData = filteredData.find(d => new Date(d.ts).getTime() === date.getTime());
    const oat = matchedData?.oat;
    const rate = matchedData?.rate;
    
    setTooltip({
      visible: true,
      text: `${rate?.toFixed(1)}kW @ ${oat?.toFixed(1)} °C, ${formattedDate}`,
      x: event.clientX,
      y: event.clientY
    });
  };

  // Handle hiding tooltip
  const handleMouseLeave = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  if (weeks.length === 0) return <div>No data available</div>;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', position: 'relative' }}>
      {/* Custom tooltip */}
      {tooltip.visible && (
        <div 
          style={{
            position: 'fixed',
            top: tooltip.y + 10,
            left: tooltip.x + 10,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            fontSize: '12px',
            zIndex: 1000,
            pointerEvents: 'none'
          }}
        >
          {tooltip.text}
        </div>
      )}
    
      {/* Header row with day names */}
      <div style={{ display: 'flex', gap: '1px', marginBottom: '4px' }}>
        <div style={{ 
          width: '100px', 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
          padding: '4px 8px',
          backgroundColor: '#f5f5f5',
          borderRadius: '4px',
          fontWeight: 'bold',
          fontSize: '12px'
        }}>
          Week
        </div>
        
        {dayNames.map((day, index) => (
          <div key={index} style={{ 
            width: '50px', 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 0',
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            fontWeight: 'bold',
            fontSize: '12px'
          }}>
            {day}
          </div>
        ))}
      </div>
      
      {/* Week rows */}
      {weeks.map((week, weekIndex) => (
        <div key={weekIndex} style={{ display: 'flex', gap: '1px' }}>
          {/* Week label */}
          <div style={{ 
            width: '100px', 
            display: 'flex', 
            alignItems: 'center',
            padding: '0 8px',
            backgroundColor: '#f5f5f5',
            borderRadius: '4px',
            fontSize: '12px'
          }}>
            {(() => {
              // Use the first day of the week (Monday) for the week label
              const monday = week[0];
              return `${monday.date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}`;
            })()}
          </div>
          
          {/* Days of the week */}
          {week.map((day, dayIndex) => (
            <div
              key={dayIndex}
              style={{ 
                border: '1px solid #ccc',
                position: 'relative',
                width: '50px',
                backgroundColor: day.data && day.data.excluded ? 'lightgray' : 'white'
              }}
              onMouseEnter={(e) => handleMouseEnter(e, day.date)}
              onMouseLeave={handleMouseLeave}
            >
              {day.data && (
                <React.Fragment>
                  <DayProfile
                    meterReadings={day.data.meterReading}
                    weatherData={day.data.weatherData}
                    width={50}
                    height={10}
                    colors={colors}
                  />
                </React.Fragment>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default WeekProfiles;
