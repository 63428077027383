import { ModeledData } from '../../domain/ModeledData';

/**
 * Defines supported clustering methods
 */
export enum ClusteringMethod {
  KMEANS = 'kmeans',
  GMM = 'gmm',
  HIERARCHICAL = 'hierarchical'
}

/**
 * Defines seasons of the year
 */
export enum Season {
  WINTER = 3,
  SPRING = 2,
  SUMMER = 0,
  AUTUMN = 1
}

/**
 * Options for clustering algorithms
 */
export interface ClusteringOptions {
  /** Number of clusters to create, -1 for auto-detection */
  numClusters: number;
  /** Feature keys to use for clustering */
  selectedFeatures?: string[];
  /** Clustering method to use */
  method?: ClusteringMethod;
}

/**
 * Results from a clustering algorithm
 */
export interface ClusterResult {
  /** Array of cluster assignments */
  clusters: number[];
  /** Optional cluster centroids */
  centroids?: number[][];
  /** Optional error metric */
  error?: number;
}

/**
 * Map of feature names to their values
 */
export interface FeatureMap {
  [key: string]: number[] | number[][];
}
