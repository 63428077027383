import { euclideanDistance } from '../utils/mathUtils';

/**
 * Calculate distance between two clusters (average linkage method)
 * @param cluster1 Array of indices for points in first cluster
 * @param cluster2 Array of indices for points in second cluster
 * @param distances Distance matrix
 * @returns Average distance between clusters
 */
export function clusterDistance(
  cluster1: number[], 
  cluster2: number[], 
  distances: number[][]
): number {
  if (!cluster1.length || !cluster2.length) return Infinity;
  
  let totalDist = 0;
  let count = 0;
  
  for (const i of cluster1) {
    for (const j of cluster2) {
      totalDist += distances[i][j];
      count++;
    }
  }
  
  return totalDist / (count || 1);
}

/**
 * Hierarchical clustering implementation (agglomerative, bottom-up approach)
 * @param data Array of data points
 * @param numClusters Number of clusters to create
 * @returns Array of cluster assignments for each point
 */
export function hierarchicalClustering(data: number[][], numClusters: number): number[] {
  if (!data.length) return [];
  if (data.length <= numClusters) return data.map((_, i) => i);
  
  // Initialize each point in its own cluster
  let clusters: number[][] = data.map((_, i) => [i]);
  
  // Distance matrix between points
  let distanceMatrix: number[][] = [];
  for (let i = 0; i < data.length; i++) {
    distanceMatrix[i] = [];
    for (let j = 0; j < data.length; j++) {
      if (i === j) {
        distanceMatrix[i][j] = Infinity;
      } else {
        distanceMatrix[i][j] = euclideanDistance(data[i], data[j]);
      }
    }
  }
  
  // Merge clusters until we have the desired number
  while (clusters.length > numClusters) {
    // Find minimum distance
    let minDist = Infinity;
    let minI = 0, minJ = 0;
    
    for (let i = 0; i < clusters.length; i++) {
      for (let j = i + 1; j < clusters.length; j++) {
        const dist = clusterDistance(clusters[i], clusters[j], distanceMatrix);
        if (dist < minDist) {
          minDist = dist;
          minI = i;
          minJ = j;
        }
      }
    }
    
    // Merge clusters
    clusters[minI] = [...clusters[minI], ...clusters[minJ]];
    clusters.splice(minJ, 1);
  }
  
  // Map points to their cluster index
  const result = new Array(data.length).fill(0);
  clusters.forEach((clusterPoints, clusterIndex) => {
    clusterPoints.forEach(pointIndex => {
      result[pointIndex] = clusterIndex;
    });
  });
  
  return result;
}

/**
 * Calculate silhouette score for clustering evaluation
 * @param data Array of data points
 * @param clusterAssignments Array of cluster assignments
 * @returns Silhouette score (-1 to 1, higher is better)
 */
export function silhouetteScore(data: number[][], clusterAssignments: number[]): number {
  const n = data.length;
  if (n <= 1) return 0;
  
  const uniqueClusters = Array.from(new Set(clusterAssignments));
  if (uniqueClusters.length < 2) return -1; // Need at least 2 clusters for silhouette
  
  let totalSilhouette = 0;
  
  for (let i = 0; i < n; i++) {
    const clusterI = clusterAssignments[i];
    
    // Calculate average distance to points in same cluster (a)
    let sameClusterCount = 0;
    let totalDistanceSame = 0;
    
    for (let j = 0; j < n; j++) {
      if (i !== j && clusterAssignments[j] === clusterI) {
        totalDistanceSame += euclideanDistance(data[i], data[j]);
        sameClusterCount++;
      }
    }
    
    // If point is alone in its cluster
    if (sameClusterCount === 0) {
      continue; // Skip this point in silhouette calculation
    }
    
    const a = totalDistanceSame / sameClusterCount;
    
    // Calculate average distance to points in nearest different cluster (b)
    const avgDistanceByCluster: { [key: number]: { total: number; count: number } } = {};
    
    for (let j = 0; j < n; j++) {
      const clusterJ = clusterAssignments[j];
      if (clusterJ !== clusterI) {
        if (!avgDistanceByCluster[clusterJ]) {
          avgDistanceByCluster[clusterJ] = { total: 0, count: 0 };
        }
        avgDistanceByCluster[clusterJ].total += euclideanDistance(data[i], data[j]);
        avgDistanceByCluster[clusterJ].count++;
      }
    }
    
    // Find nearest cluster average
    let minAvgDistance = Infinity;
    for (const cluster in avgDistanceByCluster) {
      const { total, count } = avgDistanceByCluster[cluster];
      const avgDistance = total / count;
      if (avgDistance < minAvgDistance) {
        minAvgDistance = avgDistance;
      }
    }
    
    const b = minAvgDistance;
    
    // Calculate silhouette for this point
    const silhouette = (b - a) / Math.max(a, b);
    totalSilhouette += silhouette;
  }
  
  return totalSilhouette / n;
}

/**
 * Find optimal number of clusters using silhouette score
 * @param data Array of data points
 * @param minClusters Minimum number of clusters to consider
 * @param maxClusters Maximum number of clusters to consider
 * @returns Optimal number of clusters
 */
export function findOptimalClusters(
  data: number[][], 
  minClusters: number = 2, 
  maxClusters: number = 10
): number {
  if (data.length <= minClusters) return Math.max(2, data.length - 1);
  
  // Calculate silhouette score for each number of clusters
  let bestScore = -1;
  let bestNumClusters = minClusters;
  
  for (let k = minClusters; k <= Math.min(maxClusters, data.length - 1); k++) {
    const clusterAssignments = hierarchicalClustering(data, k);
    const score = silhouetteScore(data, clusterAssignments);
    
    if (score > bestScore) {
      bestScore = score;
      bestNumClusters = k;
    }
  }
  
  return bestNumClusters;
}
