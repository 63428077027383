import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { fetchDayProfiles, fetchWeatherDays, modelDayData,  } from '../services/meterDataService';
import { NumberedModelRow } from '../domain/NumberedModelRow';
import WeekProfiles from './WeekProfiles';
import OATModesChart from './charts/OATModesChart';
import { ModeledData } from '../domain/ModeledData';
import { DayData } from '../domain/DayData';
import ModeLegend from './ModeLegend';
import FeatureSelector from './FeatureSelector';
import { numberEvents } from '../model/kintelligence/eventNumbering';
import { summarisedEvents } from '../model/kintelligence/eventSummary';
import EventHistoryChart from './charts/EventHistoryChart';
 
import TimeModeChart from './charts/TimeModeChart';
import { clusterData, ClusteringMethod, FEATURE_NAMES } from '../services/clusterService';
 

// Define a type for the feature keys
type FeatureKey = keyof typeof FEATURE_NAMES;

const DEFAULT_NUM_CLUSTERS = 3;
const colors = ["#aabbcc", "#D95F02", "#7570B3", "#E7298A", "#66A61E", "#E6AB02", "#A6761D", "#9e1b56", "#D95F02", "#0000ff", "#ff0000", "#00ff00", "#E6AB02", "#A6761D", "blue", "#1B9E77"];

const SingleMeterYear: React.FC = () => {
  const { meterkey } = useParams<{ meterkey: string }>();
  const { utility } = useParams<{ utility: string }>();
  const [loading, setLoading] = useState<boolean>(true);
  const [modeledData, setModeledData] = useState<ModeledData[]>();
  const [numClusters, setNumClusters] = useState<number>(DEFAULT_NUM_CLUSTERS);
  const [dayProfilesRaw, setDayProfilesRaw] = useState<any[]>([]);
  const [weatherDays, setWeatherDays] = useState<any[]>([]);
  const [modelWithEvents, setModelWithEvents] = useState<NumberedModelRow[]>([]);
  const [eventSummary, setEventSummary] = useState<any[]>([]);
 
  // Add state for visible modes and excluded data
  const [visibleModes, setVisibleModes] = useState<Set<number>>(new Set());
  const [showExcluded, setShowExcluded] = useState<boolean>(false);
  
  // Add state for days to fetch
  const [daysToFetch, setDaysToFetch] = useState<number>(1095);
  
  // Add state for selected features
  const [selectedFeatures, setSelectedFeatures] = useState<FeatureKey[]>([
    
    'FFT_MAGNITUDES',
     
   
  ] as FeatureKey[]);
  
  // Add state for clustering method
  const [clusteringMethod, setClusteringMethod] = useState<ClusteringMethod>(ClusteringMethod.HIERARCHICAL);

  // Add error state
  const [error, setError] = useState<string | null>(null);

  // Fetch raw data only once
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching starts
      try {
        const [profiles, weather] = await Promise.all([
          fetchDayProfiles(meterkey || '', daysToFetch), 
          fetchWeatherDays(meterkey || '', daysToFetch)
        ]);
        
        if (profiles && weather) {
          setDayProfilesRaw(profiles);
          setWeatherDays(weather);
        }
      } catch (error) {
        console.error("Error fetching day profiles or weather days:", error);
        setError("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [meterkey, daysToFetch]);

  // Process data when numClusters or selectedFeatures changes
  useEffect(() => {
    const processData = async () => {
      if (!dayProfilesRaw.length || !weatherDays.length) return;
      
      try {
        setLoading(true);
        setError(null); // Clear any previous errors
        
        // Check if we have enough features selected
        if (selectedFeatures.length === 0) {
          setError("Please select at least one feature for clustering");
          setLoading(false);
          return;
        }
        
       
        // Ensure we have both arrays and they are the same length (if not, take minimum length)
        if (dayProfilesRaw && weatherDays) {
          // Create a map of date -> weatherDay for quick lookup
          const weatherByDate = new Map<string, any>();
          weatherDays.forEach(weatherDay => {
            if (!weatherDay.date) return;
            const date = new Date(weatherDay.date);
            const dateStr = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
            weatherByDate.set(dateStr, weatherDay);
          });
          
          // Match day profiles with corresponding weather data
          const combinedData: DayData[] = [];
          
          dayProfilesRaw.forEach(profile => {
            if (!profile.ts) return; // Skip if no timestamp
            
            const profileDate = new Date(profile.ts);
            const dateStr = profileDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
            
            const matchingWeather = weatherByDate.get(dateStr);
            if (matchingWeather) {
              combinedData.push({
                excluded: false,
                meterReading: profile,
                weatherData: matchingWeather
              });
            }
          });
          
          if (combinedData.length === 0) {
            setError("No matching data found between profiles and weather");
            setLoading(false);
            return;
          }
          
          // Create an array of NumberedModelRow objects
          const numberedData: ModeledData[] = combinedData.map(d => {
            return {
              ts: d.meterReading.ts,
              oat: d.weatherData.avgOat,
              rate: d.meterReading.rate,
              profile: d.meterReading.profile,
              
              exclude: d.excluded, 
              cause: 'Clean',
              mode: d.meterReading.mode || 0,
              // Add missing properties required by ModeledData
              event_no: 0,
              pred: 0,
              waste: 0,
              readings: '',
              used: false,
              normalizedRate: 0,
              maxIdx: 0,
              minIdx: 0,
              maxInclineIdx: 0,
              maxDeclineIdx: 0,
              modeChanges: 0
            }
          });
 // Pass selectedFeatures to clusterData
 const dayProfiles = clusterData(numberedData, {numClusters, selectedFeatures, method: clusteringMethod});
        
 if (!dayProfiles) {
   setError("Failed to cluster data");
   setLoading(false);
   return;
 }

          // Process each day in parallel
          const clustersToProcess: number[] = Array.from({length: numClusters}, (v, k)=>k+1);
          
          try {
            const processedDays = await Promise.all(
              clustersToProcess.map(async (cluster) => {
                // Let modelDayData do its own filtering based on the day/mode parameter
                return await modelDayData(
                  dayProfiles.map(d => ({
                    ...d, 
                    event_no: 0, 
                    exclude: false
                  })),
                  cluster,
                  utility||'GAS'
                );
              })
            );

            // Combine all processed days into a single modeledData array
            const modeledDataObj: ModeledData[] = [];
            processedDays.forEach((result, index) => {
             
              if (result && result.length > 0) {
                modeledDataObj.push(...result);
              }
            });
            
            if (modeledDataObj.length === 0) {
              setError("No modeled data available after processing");
            }
            
            setModeledData(modeledDataObj.sort((a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime()));
            const modelWithEvents = numberEvents(modeledData||[]);
            const eventSummary = summarisedEvents(modelWithEvents);
         
            setEventSummary(eventSummary);
            setModelWithEvents(modelWithEvents);
            // Initialize visibleModes with all available modes when data changes
            setVisibleModes(new Set(clustersToProcess));
          } catch (error) {
            console.error("Error processing days:", error);
            setError(`Error processing days: ${error instanceof Error ? error.message : String(error)}`);
          }
        }
      } catch (error) {
        console.error("Error processing data:", error);
        setError(`Error processing data: ${error instanceof Error ? error.message : String(error)}`);
      } finally {
        setLoading(false);
      }
    };

    processData();
  }, [dayProfilesRaw, weatherDays, numClusters, selectedFeatures, clusteringMethod]); // Add selectedFeatures and clusteringMethod as dependency

  const handleClusterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (value >= 1 && value <= 15) { 
      setNumClusters(value);
    }
  };

  // Add handler for days to fetch change
  const handleDaysToFetchChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(event.target.value);
    setDaysToFetch(value);
  };

  // Add toggle functions for modes and excluded data
  const toggleMode = useCallback((mode: number) => {
    setVisibleModes(prev => {
      const newSet = new Set(prev);
      if (newSet.has(mode)) {
        newSet.delete(mode);
      } else {
        newSet.add(mode);
      }
      return newSet;
    });
  }, []);

  const toggleExcluded = useCallback(() => {
    setShowExcluded(prev => !prev);
  }, []);

  // Handle feature selection changes
  const handleFeaturesChange = useCallback((features: FeatureKey[]) => {
    setSelectedFeatures(features);
  }, []);


  if (loading) {
    return <div>Loading...</div>;
  }

  // Create mode labels
  const modeLabels = Array.from({length: numClusters}, (_, k) => `M ${k+1}`);
  
  // Count the number of data points in each mode
  const modeCounts = new Map<number, number>();
  if (modeledData) {
    modeledData.forEach(item => {
      const mode = item.mode;
      modeCounts.set(mode, (modeCounts.get(mode) || 0) + 1);
    });
  }

  return (
    <div>
      {error && (
        <div style={{
          padding: '10px',
          margin: '10px 0',
          backgroundColor: '#ffebee',
          color: '#c62828',
          borderRadius: '4px',
          border: '1px solid #ef9a9a'
        }}>
          <strong>Error:</strong> {error}
        </div>
      )}
      <div style={{ padding: '10px', display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <label htmlFor="numClusters">Number of Clusters:</label>
          <input
            id="numClusters"
            type="range"
            min="1"
            max="25"
            value={numClusters}
            onChange={handleClusterChange}
            style={{ width: '200px' }}
          />
          <span>{numClusters}</span>
        </div>
        
        {/* Add FeatureSelector */}
        <div style={{ marginLeft: 'auto' }}>
          <FeatureSelector 
            selectedFeatures={selectedFeatures}
            onFeaturesChange={handleFeaturesChange}
          />
        </div>
        
        {/* Add method selector */}
        <div className="method-selector">
          <label>Clustering Method:</label>
          <select 
            value={clusteringMethod} 
            onChange={(e) => setClusteringMethod(e.target.value as ClusteringMethod)}
          >
            <option value="kmeans">K-Means</option>
            <option value="gmm">Gaussian Mixture Model</option>
            <option value="hierarchical">Hierarchical</option>
          </select>
        </div>
        
        {/* Add days to fetch selector */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <label>Days to Fetch:</label>
          <select 
            value={daysToFetch} 
            onChange={handleDaysToFetchChange}
          >
            <option value="365">1 year</option>
            <option value="730">2 years</option>
            <option value="1095">3 years</option>
            <option value="1460">4 years</option>
            <option value="1825">5 years</option>
          </select>
        </div>
      </div>
      
      {/* Add ModeLegend component */}
      <div style={{ padding: '10px' }}>
        <ModeLegend
          modes={modeLabels}
          colors={colors}
          visibleModes={visibleModes}
          toggleMode={toggleMode}
          showExcluded={showExcluded}
          toggleExcluded={toggleExcluded}
          width={800}
          modeCounts={modeCounts}
          data={modeledData || []}
        />
      </div>
      
      <div style={{
        display: 'flex',
        gap: '20px',
        height: '600px' // Match OATModesChart height
      }}>
        {/* OATModesChart Panel */}
        <div style={{
          flex: '1',
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: 'white'
        }}>

          <OATModesChart
            modes={modeLabels}
            data={modeledData || []}
            width={600}
            height={580} // Leave room for padding
            colors={colors}
            visibleModes={visibleModes} 
            showExcluded={showExcluded} 
          />
        </div>

         {/* OATModesChart Panel */}
       

        {/* WeekProfiles Panel */}
        <div style={{
          flex: '1',
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: 'white',
          overflow: 'auto'
        }}>
          {modeledData && modeledData.length > 0 && (
            <WeekProfiles
              numberedModelData={modeledData}
              colors={colors}
              visibleModes={visibleModes} // Pass visibleModes to WeekProfiles
            />
          )}
        </div>
      </div>
     
      
      {/* Add TimeModeChart component */}
      <div style={{
          marginTop: '20px',
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: 'white'
        }}>
        <TimeModeChart 
          data={modeledData || []}
          cleanedReadings={modelWithEvents}
          modes={modeLabels}
          colors={colors}
          visibleModes={visibleModes}
          width={1200} 
          height={600}
          xDomain={[new Date(modelWithEvents[0]?.ts), new Date(modelWithEvents[modelWithEvents.length-1]?.ts)]}
        />
      </div>
      
      <div style={{
         
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: 'white'
        }}>

<EventHistoryChart 
              eventList={eventSummary || []} 
              data={modelWithEvents} 
              width={1200} 
              height={600}
              xDomain={[new Date(modelWithEvents[0]?.ts), new Date(modelWithEvents[modelWithEvents.length-1]?.ts)]}
            />
        </div>

      
      {/* Add feature selection info */}
      <div style={{ 
        marginTop: '20px', 
        padding: '10px', 
        backgroundColor: '#f8f8f8', 
        borderRadius: '4px',
        fontSize: '14px'
      }}>
        <h3 style={{ margin: '0 0 10px 0' }}>Selected Features ({selectedFeatures.length})</h3>
        <div style={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: '8px' 
        }}>
          {selectedFeatures.map(feature => (
            <div key={feature} style={{ 
              padding: '4px 8px', 
              backgroundColor: '#e6f7ff', 
              borderRadius: '4px',
              fontSize: '12px'
            }}>
              {FEATURE_NAMES[feature]}
            </div>
          ))}
        </div>
      </div> 
    </div>
  );
};

export default SingleMeterYear;
