import React, { useState } from 'react';
import { Button } from 'primereact/button';

interface DownloadChartButtonProps {
  chartRef: React.RefObject<SVGSVGElement>;
  filename: string;
}

const DownloadChartButton: React.FC<DownloadChartButtonProps> = ({ chartRef, filename }) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadChart = () => {
    if (!chartRef.current) return;
    setIsLoading(true);

    const svg = chartRef.current;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const svgData = new XMLSerializer().serializeToString(svg);

    // Encode the SVG data properly for Unicode characters
    const encodedData = encodeURIComponent(svgData);
    const img = new Image();

    // Set canvas size to match SVG
    canvas.width = svg.width.baseVal.value;
    canvas.height = svg.height.baseVal.value;

    img.onload = () => {
      if (!ctx) return;

      // Draw white background
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the image
      ctx.drawImage(img, 0, 0);

      // Convert to PNG and download
      const link = document.createElement('a');
      link.download = `${filename}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();

      // Reset loading state after download starts
      setTimeout(() => setIsLoading(false), 100);
    };

    // Use encoded data in the image source
    img.src = `data:image/svg+xml,${encodedData}`;
  };

  return (
    <Button
      icon={isLoading ? "pi pi-spinner pi-spin" : "pi pi-download"}
      onClick={downloadChart}
      className="p-button-text"
      style={{ position: 'absolute', top: '20px', right: '5px' }}
      disabled={isLoading}
    />
  );
};

export default DownloadChartButton;
