import { ModeledData } from '../../domain/ModeledData';
import { 
  normalize, 
  standardize, 
  countUniqueValues,
  mean
} from './utils/mathUtils';
import { FEATURE_EXTRACTION } from './constants';
import { nkmeansOrdered } from '../../model/kfeature/kmeans';

/**
 * Calculate the maximum incline (increase between consecutive points)
 * @param profile Array of values representing a daily profile
 * @returns Maximum incline value
 */
export function calculateMaxIncline(profile: number[]): number {
  if (!profile || profile.length <= 1) return 0;
  
  const incline = [];
  for (let i = 1; i < profile.length; i++) {
    incline.push(profile[i] - profile[i - 1]);
  }
  return Math.max(...incline);
}

/**
 * Calculate the maximum decline (decrease between consecutive points)
 * @param profile Array of values representing a daily profile
 * @returns Maximum decline value
 */
export function calculateMaxDecline(profile: number[]): number {
  if (!profile || profile.length <= 1) return 0;
  
  const decline = [];
  for (let i = 1; i < profile.length; i++) {
    decline.push(profile[i - 1] - profile[i]);
  }
  return Math.max(...decline);
}

/**
 * Calculate sharpness of half hourly energy consumption daily profile
 * A measure of how close the profile is to a rectangle
 * @param readings Array of values representing a daily profile
 * @returns Sharpness value
 */
export function calculateSharpness(readings: number[] | undefined): number {
  if (!readings || readings.length !== FEATURE_EXTRACTION.PERFECT_RECTANGLE.length) {
    return 0;
  }
  
  return readings.reduce(
    (sum, reading, index) => 
      sum + Math.abs(reading - FEATURE_EXTRACTION.PERFECT_RECTANGLE[index]),
    0
  );
}

/**
 * Calculate cluster error using a 2-cluster k-means approach
 * @param readings Array of values representing a daily profile
 * @returns Cluster error value
 */
export function calculateClusterError(readings: number[] | undefined): number {
  // Return 0 if readings is undefined or empty
  if (!readings || readings.length <= 4) {
    return 0;
  }
  if (countUniqueValues(readings) < 3) {
    return 0;
  }
  
  try {
    const normalizedReadings = normalize(readings);
    const clusters = nkmeansOrdered(normalizedReadings, 2);
    // return sum of squares of differences between centers
    return clusters.withinss.reduce((sum, ss) => sum + ss, 0);
  } catch (error) {
    console.error('Error calculating cluster error:', error);
    return 0;
  }
}

/**
 * Returns the sum of normalized readings of the cluster with the highest center
 * @param readings Array of values representing a daily profile
 * @returns Power high cluster value
 */
export function calculatePowerHighCluster(readings: number[] | undefined): number {
  // Return 0 if readings is undefined or empty
  if (!readings || readings.length <= 4) {
    return 0;
  }
  if (countUniqueValues(readings) < 3) {
    return 0;
  }
  
  try {
    const normalizedReadings = normalize(readings);
    const clusters = nkmeansOrdered(normalizedReadings, 2);
    if (countUniqueValues(clusters.cluster) < 2) {
      return 0;
    }
    // return sum of squares of differences between centers
    return clusters.centers[1];
  } catch (error) {
    console.error('Error calculating power high cluster:', error);
    return 0;
  }
}

/**
 * Calculate mid range values using 3-cluster approach
 * @param readings Array of values representing a daily profile
 * @returns Mid range value
 */
export function midRangeValues(readings: number[] | undefined): number {
  // Return 0 if readings is undefined or empty
  if (!readings || readings.length <= 4) {
    return 0;
  }
  if (countUniqueValues(readings) < 3) {
    return 0;
  }
  
  try {
    const normalizedReadings = normalize(readings);
    const clusters = nkmeansOrdered(normalizedReadings, 3);
    if (countUniqueValues(clusters.cluster) < 3) {
      return 0;
    }
    // return size of middle cluster
    return clusters.cluster.filter(c => c === 1).length;
  } catch (error) {
    console.error('Error calculating midRangeValues:', error);
    return 0;
  }
}

/**
 * Calculate how symmetric the shape of the profile is
 * @param profile Array of values representing a daily profile
 * @returns Symmetry value
 */
export function calculateSymmetry(profile: number[]): number {
  if (!profile || profile.length === 0) return 0;
   
  let symmetry = 0;
  for (let i = 0; i < profile.length / 2; i++) {
    symmetry += Math.abs(profile[i] - profile[profile.length - 1 - i]);
  }
  return symmetry;
}

/**
 * Determine season from a timestamp
 * @param ts Timestamp string
 * @returns Season value (0=Summer, 1=Autumn, 2=Spring, 3=Winter)
 */
export function calculateSeason(ts: string): number {
  const WINTER = 3;
  const SPRING = 2;
  const SUMMER = 0;
  const AUTUMN = 1;
  
  const date = new Date(ts);
  const month = date.getMonth();
  
  return month < 3 || month === 11 
    ? WINTER 
    : month < 6 
      ? SPRING 
      : month < 9 
        ? SUMMER 
        : AUTUMN;
}

/**
 * Calculate percentage of daylight hours power relative to rest
 * @param profile Daily profile array
 * @returns Daylight percentage
 */
export function calculateDaylightPercentage(profile: number[]): number {
  if (!profile || profile.length === 0) return 0;
  
  const sumDaylightPower = profile
    .slice(FEATURE_EXTRACTION.DAYLIGHT_HOURS_START_INDEX, FEATURE_EXTRACTION.DAYLIGHT_HOURS_END_INDEX)
    .reduce((a, b) => a + b, 0);
    
  const sumTotalPower = profile.reduce((a, b) => a + b, 0);
  const sumRestPower = sumTotalPower - sumDaylightPower;
  
  if (sumRestPower === 0) return 0;
  return (sumDaylightPower / sumRestPower) * 100;
}

/**
 * Extract date-related features from modeled data
 * @param rawData Array of ModeledData objects
 * @returns Object containing date feature arrays
 */
export function extractDateFeatures(rawData: ModeledData[]): Record<string, number[]> {
  return {
    isoDow: rawData.map(p => new Date(p.ts).getDay()),
    calendarWeek: rawData.map(p => {
      const date = new Date(p.ts);
      // Get the first day of the year
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
      // Calculate the number of days between the date and the first day of the year
      const daysSinceFirstDay = Math.floor(
        (date.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000)
      );
      // Calculate the week number (add 1 because weeks are 1-indexed)
      return Math.ceil((daysSinceFirstDay + firstDayOfYear.getDay() + 1) / 7);
    }),
    isSunday: rawData.map(p => new Date(p.ts).getDay() === 0 ? 1 : 0),
    isSaturday: rawData.map(p => new Date(p.ts).getDay() === 6 ? 1 : 0),
    isWorkingDay: rawData.map(p => {
      const day = new Date(p.ts).getDay();
      return day >= 1 && day <= 5 ? 1 : 0;
    }),
    year: rawData.map(p => new Date(p.ts).getFullYear()),
    month: rawData.map(p => new Date(p.ts).getMonth()),
    season: rawData.map(p => calculateSeason(p.ts))
  };
}

/**
 * Extract temperature-related features
 * @param rawData Array of ModeledData objects
 * @returns Object containing temperature feature arrays
 */
export function extractTemperatureFeatures(rawData: ModeledData[]): Record<string, number[]> {
  const oats = rawData.map(p => p.oat);
  
  return {
    oat: oats,
    heatingDemand: oats.map(oat => oat < 15 ? 15 - oat : 0),
    coolingDemand: oats.map(oat => oat > 25 ? oat - 25 : 0),
    normalizedRateByHeatingDemand: rawData.map((p, i) => p.rate === 0 ? 0 : p.oat * p.rate)
  };
}

/**
 * Extract profile-related features from modeled data
 * @param rawData Array of ModeledData objects
 * @returns Object containing profile feature arrays
 */
export function extractProfileFeatures(rawData: ModeledData[]): Record<string, number[]> {
  return {
    maxIndices: rawData.map(p => p.maxIdx),
    minIndices: rawData.map(p => p.minIdx),
    maxInclineIndices: rawData.map(p => p.maxInclineIdx),
    maxDeclineIndices: rawData.map(p => p.maxDeclineIdx),
    maxIncline: rawData.map(p => calculateMaxIncline(p.profile || [])),
    maxDecline: rawData.map(p => calculateMaxDecline(p.profile || [])),
    normalizedRate: rawData.map(p => p.normalizedRate || 0),
    modeChanges: rawData.map(p => p.modeChanges || 0),
    rates: rawData.map(p => p.rate || 0),
    sharpness: rawData.map(p => calculateSharpness(p.profile)),
    clusterError: rawData.map(p => calculateClusterError(p.profile)),
    powerHighCluster: rawData.map(p => calculatePowerHighCluster(p.profile)),
    midRange: rawData.map(p => midRangeValues(p.profile)),
    startStopDiff: rawData.map(p => {
      if (!p.profile || p.profile.length === 0) return 0;
      return p.profile[p.profile.length - 1] - p.profile[0];
    }),
    onOffDiff: rawData.map(p => p.maxDeclineIdx - p.maxInclineIdx),
    daylightPercentage: rawData.map(p => calculateDaylightPercentage(p.profile || [])),
    symmetry: rawData.map(p => calculateSymmetry(p.profile || []))
  };
}
