import { NumberedModelRow } from "../../domain/NumberedModelRow";
import { PentileDataPoint, PentileDataPointCompact } from "../../domain/PentileDataPoint";

  
  // Function to compute ntile
  function ntile(values: number[], n: number): number[] {
    const len = values.length;
    const valueIndexPairs = values.map((value, index) => ({ value, index }));
    valueIndexPairs.sort((a, b) => a.value - b.value);
  
    const ranks: number[] = [];
    for (let i = 0; i < len; i++) {
      ranks[valueIndexPairs[i].index] = i + 1;
    }
  
    return ranks.map(rank => Math.floor((rank - 1) * n / len) + 1);
  }
  
  export function assignPentile(readings: NumberedModelRow[]): NumberedModelRow[] {
    // Group readings by iso_dow
    const groups: { [key: string]: NumberedModelRow[] } = {};
    readings.forEach(reading => {
      const key = reading.mode;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(reading);
    });
  
    const result: NumberedModelRow[] = [];
  
    // For each group, compute pentile and create new readings
    for (const key in groups) {
      const group = groups[key];
      const oats = group.map(reading => reading.oat);
      const pentiles = ntile(oats, 5);
  
      for (let i = 0; i < group.length; i++) {
        const reading = group[i];
        const pentile = pentiles[i];
        result.push({
          ...reading,
          pentile: pentile,
        });
      }
    }
  
    return result;
  }


  
  // Helper function to group data by a key
  function groupBy<T>(array: T[], keyGetter: (item: T) => string): { [key: string]: T[] } {
    return array.reduce((result, item) => {
      const key = keyGetter(item);
      (result[key] = result[key] || []).push(item);
      return result;
    }, {} as { [key: string]: T[] });
  }
  
  // Helper function to calculate the mean of an array of numbers
  function mean(numbers: number[]): number {
    const validNumbers = numbers.filter(num => num !== null && num !== undefined && !isNaN(num));
    const total = validNumbers.reduce((acc, num) => acc + num, 0);
    return total / validNumbers.length;
  }
  
  // Placeholder for the profile_indexed_to_peak function
  function profileIndexedToPeak(reading: number): number {
    // Implement the actual logic as needed
    return reading; // Adjust this to reflect the correct computation
  }
  
  export function pentileProfiler(pentiledModel: NumberedModelRow[], rpd: number): PentileDataPoint[] {
    const startDate = new Date(Date.UTC(2024, 0, 1)); // January 1st, 2024 in UTC
    
    // Step 1: Select necessary properties and unnest readings
    const unnestedData = pentiledModel.flatMap(model => {
        // Skip if readings is undefined or empty
        if (!model.readings) {
            return [];
        }
        
        // Split the readings string and convert to numbers
        const readingValues = model.readings.split(',')
            .map(r => r.trim())
            .filter(r => r !== '')
            .map(r => parseFloat(r));
            
        // Create an entry for each reading
        return readingValues.map((reading, idx) => ({
            iso_dow: model.mode,
            ts: model.ts,
            pentile: model.pentile || 0,
            cause: model.cause,
            reading: reading,
            reading_ordinal: idx + 1
        }));
    });

    // Step 2: Group by iso_dow and pentile
    const groupedData = groupBy(unnestedData, item => `${item.iso_dow}_${item.pentile}_${item.reading_ordinal}`);

    // Step 3: Calculate average reading for each group
    const profiles: PentileDataPoint[] = [];
    
    for (const key in groupedData) {
        const group = groupedData[key];
        if (group.length === 0) continue;

        const [iso_dow, pentile, reading_ordinal] = key.split('_').map(Number);
        const avgReading = mean(group.map(item => item.reading));
        
        // Calculate time_of_week based on iso_dow and reading_ordinal
        const minutesInDay = 24 * 60;
        const minutesPerReading = minutesInDay / rpd;
        const minutesOffset = (iso_dow - 1) * minutesInDay + (reading_ordinal - 1) * minutesPerReading;
        const timeOfWeek = new Date(startDate.getTime() + minutesOffset * 60 * 1000);
        
        profiles.push({
            iso_dow,
            pentile,
            reading: avgReading,
            index: profileIndexedToPeak(avgReading),
            time_of_week: timeOfWeek
        });
    }
    
    return profiles;
}
    export function pentileDataPointCompact(profiles: PentileDataPoint[]): PentileDataPointCompact[] {
    // Step 4: Convert to PentileDataPointCompact. Group by time_of_week. Redings should be an array of "reading", sorted by pentile
    const pentileDataPointCompact = groupBy(profiles, item => item.time_of_week.toISOString());
   
    const pentileDataPointCompactArray: PentileDataPointCompact[] = [];
    
    for (const key in pentileDataPointCompact) {
        const group = pentileDataPointCompact[key];
        if (group.length === 0) continue;    

        const timeOfWeek = new Date(key);
        // sort by pentile, not by reading
        const sortedGroup = group.sort((a, b) => a.pentile - b.pentile);
        const readings = sortedGroup.map(item => item.reading);
        
        pentileDataPointCompactArray.push({
            time_of_week: timeOfWeek,
            reading: readings
        });
    }

    return pentileDataPointCompactArray;

   
}