/**
 * Feature names and their display names
 */
export const FEATURE_NAMES = {
  MAX_INCLINE: 'Maximum Incline',
  MAX_DECLINE: 'Maximum Decline',
  MAX_INDEX: 'Maximum Index',
  MIN_INDEX: 'Minimum Index',
  MAX_INCLINE_INDEX: 'Max Incline Index',
  MAX_DECLINE_INDEX: 'Max Decline Index',
  SHARPNESS: 'Sharpness',
  NORMALIZED_RATE: 'Normalized Rate',
  MID_RANGE: 'Mid Range',
  START_STOP_DIFF: 'Start-Stop Difference',
  CLUSTER_ERROR: 'Cluster Error',
  POWER_HIGH_CLUSTER: 'Power High Cluster',
  RATES: 'Rates',
  MODE_CHANGES: 'Mode Changes',
  ON_OFF_DIFF: 'On-Off Difference',
  MEAN_RATES: 'Mean Rates',
  STD_RATES: 'Standard Deviation',
  MIN_MAX_DIFF: 'Min-Max Difference',
  PEAK_TO_MEAN: 'Peak to Mean Ratio',
  AUTOCORRELATION: 'Autocorrelation',
  ENTROPY: 'Entropy',
  FFT_MAGNITUDES: 'FFT Magnitudes',
  ISO_DOW: 'ISO DOW',
  IS_SUNDAY: 'Is Sunday',
  IS_SATURDAY: 'Is Saturday',
  IS_WORKING_DAY: 'Is Working Day',
  DAYLIGHT_PERCENTAGE: 'Daylight Percentage',
  YEAR: 'Year',
  MONTH: 'Month',
  CALENDAR_WEEK: 'Calendar Week',
  OAT: 'OAT',
  SYMMETRY: 'Symmetry',
  SEASON: 'Season',
  READING_INDEX: 'Consecutive Days'
};

/**
 * Clustering configuration parameters
 */
export const CLUSTERING_CONFIG = {
  DEFAULT_MIN_CLUSTERS: 2,
  DEFAULT_MAX_CLUSTERS: 10,
  MAX_ITERATIONS: 100,
  CONVERGENCE_THRESHOLD: 1e-4
};

/**
 * Feature extraction configuration
 */
export const FEATURE_EXTRACTION = {
  DAYLIGHT_HOURS_START_INDEX: 12,
  DAYLIGHT_HOURS_END_INDEX: 37,
  FFT_MAGNITUDES_LIMIT: 13,
  PERFECT_RECTANGLE: [0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,0,0,0,0,0,0]
};
