import React from 'react';
import { ModeledData } from '../domain/ModeledData';

interface ModeSummaryProps {
  mode: number;
  data: ModeledData[];
  color: string;
}

/**
 * Component that displays statistical information about a specific cluster/mode
 * Shows average error and distribution of days by weekday and month
 */
const ModeSummary: React.FC<ModeSummaryProps> = ({ mode, data, color }) => {
  // Filter data for just this mode
  const modeData = data.filter(item => item.mode === mode);
  
  // Calculate average error (waste)
  const avgError = modeData.length > 0 
    ? modeData.reduce((sum, item) => sum + (item.waste || 0), 0) / modeData.length
    : 0;
  
  // Create a mapping of days of week
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
  // Get years distribution
  const yearCounts = new Map<number, number>();
  modeData.forEach(item => {
    const date = new Date(item.ts);
    const year = date.getFullYear();
    yearCounts.set(year, (yearCounts.get(year) || 0) + 1);
  });
  
  // Sort years
  const sortedYears = Array.from(yearCounts.keys()).sort();
  
  // Initialize distribution matrix with zeros
  const distribution: number[][] = Array(12).fill(0).map(() => Array(7).fill(0));
  
  // Fill distribution matrix - adjust for Monday as first day of week
  modeData.forEach(item => {
    const date = new Date(item.ts);
    const month = date.getMonth(); // 0-11
    const dayOfWeek = date.getDay(); // 0-6 (Sunday-Saturday)
    
    // Convert from Sunday=0 to Monday=0 format
    const adjustedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    
    // Increment count for this day of week and month
    distribution[month][adjustedDayOfWeek]++;
  });
  
  // Calculate total for each month
  const monthTotals = distribution.map(row => row.reduce((sum, count) => sum + count, 0));
  
  // Calculate total for each day of week
  const dayTotals = Array(7).fill(0);
  for (let day = 0; day < 7; day++) {
    for (let month = 0; month < 12; month++) {
      dayTotals[day] += distribution[month][day];
    }
  }
  
  // Calculate total days in the mode
  const totalDays = modeData.length;
  
  // Find max value for scaling cell colors
  const maxCount = Math.max(...distribution.flat(), 1);
  
  return (
    <div className="mode-summary" style={{ 
      border: `2px solid ${color}`,
      borderRadius: '8px',
      padding: '10px',
      margin: '5px 0',
      backgroundColor: '#f9f9f9',
      fontSize: '12px',
      maxWidth: '400px',
      boxSizing: 'border-box'
    }}>
      <h3 style={{ color, marginTop: 0, marginBottom: '8px', fontSize: '14px' }}>Mode {mode} Summary</h3>
      <div className="summary-stats" style={{ marginBottom: '8px' }}>
        <div><strong>Total Days:</strong> {totalDays}</div>
        <div><strong>Average Error:</strong> {avgError.toFixed(2)}%</div>
      </div>
      
      <h4 style={{ margin: '8px 0', fontSize: '13px' }}>Distribution by Day and Month</h4>
      <div style={{ overflowX: 'auto' }}>
        <table className="distribution-table" style={{ 
          borderCollapse: 'collapse', 
          width: '100%',
          textAlign: 'center',
          fontSize: '10px'
        }}>
          <thead>
            <tr>
              <th style={{ padding: '2px', border: '1px solid #ddd' }}></th>
              {daysOfWeek.map(day => (
                <th key={day} style={{ padding: '2px', border: '1px solid #ddd' }}>{day}</th>
              ))}
              <th style={{ padding: '2px', border: '1px solid #ddd' }}>Tot</th>
            </tr>
          </thead>
          <tbody>
            {months.map((month, monthIndex) => (
              <tr key={month}>
                <td style={{ padding: '2px', border: '1px solid #ddd', fontWeight: 'bold' }}>{month}</td>
                {daysOfWeek.map((_, dayIndex) => {
                  const count = distribution[monthIndex][dayIndex];
                  const intensity = count / maxCount;
                  
                  return (
                    <td 
                      key={`${month}-${dayIndex}`} 
                      style={{ 
                        padding: '2px', 
                        border: '1px solid #ddd',
                        backgroundColor: count > 0 
                          ? `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(color.slice(5, 7), 16)}, ${Math.min(0.1 + intensity * 0.7, 0.8)})`
                          : 'transparent'
                      }}
                    >
                      {count > 0 ? count : ''}
                    </td>
                  );
                })}
                <td style={{ padding: '2px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                  {monthTotals[monthIndex]}
                </td>
              </tr>
            ))}
            <tr>
              <td style={{ padding: '2px', border: '1px solid #ddd', fontWeight: 'bold' }}>Tot</td>
              {dayTotals.map((total, index) => (
                <td key={`total-${index}`} style={{ padding: '2px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                  {total}
                </td>
              ))}
              <td style={{ padding: '2px', border: '1px solid #ddd', fontWeight: 'bold' }}>
                {totalDays}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4 style={{ margin: '8px 0', fontSize: '13px' }}>Distribution by Year</h4>
      <div style={{ overflowX: 'auto' }}>
        {sortedYears.length > 0 ? (
          <div>
            {/* Visual bar chart representation */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '3px', marginBottom: '5px' }}>
              {sortedYears.map(year => {
                const count = yearCounts.get(year) || 0;
                const percentage = Math.round((count / totalDays) * 100);
                const barWidth = `${Math.max(percentage * 3, 5)}%`;
                
                return (
                  <div key={year} style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '10px' }}>
                    <div style={{ width: '35px', textAlign: 'right' }}>{year}</div>
                    <div 
                      style={{ 
                        height: '12px', 
                        width: barWidth, 
                        backgroundColor: color,
                        borderRadius: '2px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: '0 5px',
                        boxSizing: 'border-box',
                        color: '#fff',
                        minWidth: '25px',
                        fontSize: '9px'
                      }}
                    >
                      {count}
                    </div>
                    <div>({percentage}%)</div>
                  </div>
                );
              })}
            </div>
            
            {/* Distribution stats */}
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '11px', marginTop: '5px' }}>
              <div><strong>Years span:</strong> {sortedYears.length > 0 ? `${sortedYears[0]} - ${sortedYears[sortedYears.length-1]}` : 'N/A'}</div>
              <div><strong>Most common:</strong> {sortedYears.length > 0 ? 
                Array.from(yearCounts.entries())
                  .sort((a, b) => b[1] - a[1])[0][0]
                : 'N/A'}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center', padding: '5px', fontSize: '11px', fontStyle: 'italic' }}>No year data available</div>
        )}
      </div>
    </div>
  );
};

export default ModeSummary;
