/**
 * K-means clustering result interface
 */
export interface KMeansResult {
    centers: number[];
    cluster: number[];
    withinss: number[];
    size: number[];
}

/**
 * Cluster readings to n kmeans and return centres and stats as vectors by order of center
 * 
 * @param x - Array of numbers to cluster
 * @param n - Number of clusters
 * @returns KMeans object with centers ordered from low to high
 */
export function nkmeansOrdered(x: number[], n: number): KMeansResult {
    // Check for enough unique values
    const uniqueValues = new Set(x);
    if (uniqueValues.size < n) {
        throw new Error("Not enough unique values for n clusters");
    }

    // Initialize centers randomly
    let centers = selectRandomCenters(x, n);
    let oldCenters: number[] = [];
    let clusters: number[] = new Array(x.length);
    let iterations = 0;
    const MAX_ITERATIONS = 25;

    // Iterate until convergence or max iterations
    while (!arraysEqual(centers, oldCenters) && iterations < MAX_ITERATIONS) {
        oldCenters = [...centers];
        
        // Assign points to nearest center
        clusters = x.map(point => findNearestCenter(point, centers));
        
        // Update centers
        centers = updateCenters(x, clusters, n);
        
        iterations++;
    }

    // Calculate within-cluster sum of squares and sizes
    const withinss = calculateWithinSS(x, clusters, centers);
    const size = calculateClusterSizes(clusters, n);

    // Order centers from low to high
    const orderIndices = centers.map((_, i) => i)
                               .sort((a, b) => centers[a] - centers[b]);
    
    const orderedCenters = orderIndices.map(i => centers[i]);
    const orderedWithinss = orderIndices.map(i => withinss[i]);
    const orderedSize = orderIndices.map(i => size[i]);
    
    // Remap cluster assignments to ordered indices
    const clusterMap = new Map(orderIndices.map((oldIndex, newIndex) => [oldIndex, newIndex]));
    const orderedClusters = clusters.map(c => clusterMap.get(c)!);

    return {
        centers: orderedCenters,
        cluster: orderedClusters,
        withinss: orderedWithinss,
        size: orderedSize
    };
}

/**
 * Helper function to select random initial centers
 */
function selectRandomCenters(x: number[], n: number): number[] {
    const uniqueValues = Array.from(new Set(x));
    const centers: number[] = [];
    
    while (centers.length < n) {
        const randomIndex = Math.floor(Math.random() * uniqueValues.length);
        const value = uniqueValues[randomIndex];
        if (!centers.includes(value)) {
            centers.push(value);
        }
    }
    
    return centers;
}

/**
 * Helper function to find nearest center
 */
function findNearestCenter(point: number, centers: number[]): number {
    let minDist = Infinity;
    let nearestCenter = 0;
    
    centers.forEach((center, i) => {
        const dist = Math.abs(point - center);
        if (dist < minDist) {
            minDist = dist;
            nearestCenter = i;
        }
    });
    
    return nearestCenter;
}

/**
 * Helper function to update centers
 */
function updateCenters(x: number[], clusters: number[], n: number): number[] {
    const centers = new Array(n).fill(0);
    const counts = new Array(n).fill(0);
    
    x.forEach((point, i) => {
        const cluster = clusters[i];
        centers[cluster] += point;
        counts[cluster]++;
    });
    
    return centers.map((sum, i) => counts[i] > 0 ? sum / counts[i] : 0);
}

/**
 * Helper function to calculate within-cluster sum of squares
 */
function calculateWithinSS(x: number[], clusters: number[], centers: number[]): number[] {
    const withinss = new Array(centers.length).fill(0);
    
    x.forEach((point, i) => {
        const cluster = clusters[i];
        withinss[cluster] += Math.pow(point - centers[cluster], 2);
    });
    
    return withinss;
}

/**
 * Helper function to calculate cluster sizes
 */
function calculateClusterSizes(clusters: number[], n: number): number[] {
    const size = new Array(n).fill(0);
    clusters.forEach(cluster => size[cluster]++);
    return size;
}

/**
 * Helper function to check if arrays are equal
 */
function arraysEqual(a: number[], b: number[]): boolean {
    if (a.length !== b.length) return false;
    return a.every((val, i) => Math.abs(val - b[i]) < 1e-10);
}

/**
 * Cluster a profile using k-means
 */
export interface TypicalProfile {
    [key: string]: any;
    km3?: number[];
}

export function clusterProfile(typicalProfile: TypicalProfile, km3: KMeansResult): TypicalProfile {
    return {
        ...typicalProfile,
        km3: km3.cluster
    };
}
