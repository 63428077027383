import React, { useRef, useState } from 'react';
import { Steps } from 'primereact/steps';
import MapSelection from './MapSelection';
import DataUpload from './DataUpload';
import OccupancyHours from './OccupancyHours';
import {Button} from "primereact/button";


import XYPlot from "./charts/XYChart";
import {DataPoint, WeatherStation} from "./interfaces/interfaces";
import UserInfo from './UserInfo';
import { User } from '../domain/User';
import { Menu } from 'primereact/menu';
import { Reading } from '../domain/Reading';
import { aggregateData } from '../model/util';
import { RawDataItem } from '../domain/RawDataItem';
import TimeChart from './charts/TimeChart';
import EventHistoryChart from './charts/EventHistoryChart';
import OATChart from './charts/OATChart';
import NtileEventProfileChart from './charts/NtileEventProfileChart';
import { PentileDataPoint, PentileDataPointCompact } from '../domain/PentileDataPoint';
import { EventFeatures } from '../domain/EventFeatures';
import { NumberedModelRow } from '../domain/NumberedModelRow';
import { processMeterData } from '../services/meterDataService';
import { ModeledData } from '../domain/ModeledData';

const StepComponent: React.FC = () => {
  const menuLeft = useRef<Menu>(null);
 
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState(null); // For storing map selection
  const [csvData, setCsvData] = useState([]); // For storing uploaded CSV data
  const [occupancyData, setOccupancyData] = useState({}); // For storing occupancy data
  const [combinedData, setCombinedData] = useState<Array<RawDataItem>>([]);
  const [ user, setUser ] = useState<User>({} as User);
  const [readings, setReadings] = useState<Array<Reading>>([]);
  const [chartDimensions, setChartDimensions] = useState({ width: 800, height: 400 });
  const [selectedStation, setSelectedStation] = useState({USAF: '', WBAN: '', STATION_NAME: ''} as WeatherStation);
 
  const [eventSummary, setEventSummary] = useState<any[]>([]);
  const [pentiledProfile, setPentiledProfile] = useState<PentileDataPointCompact[]>([]);
  const [pentiledEventProfile, setPentiledEventProfile] = useState<PentileDataPoint[]>([]);
  const [eventFeatures, setEventFeatures] = useState<EventFeatures>();
  const [modelWithEvents, setModelWithEvents] = useState<NumberedModelRow[]>([]);
  const [modeledData, setModeledData] = useState<ModeledData[]>([]);
  // @ts-ignore
  const items = [
    {label: 'Upload Data', command: () => { setActiveIndex(0); }},
    {label: 'Select Location '+ selectedStation.STATION_NAME, command: () => { setActiveIndex(1); }},
    
    {label: 'Get Diagnosis', command: () => { setActiveIndex(2); }} 
  ];
  const [weatherData, setWeatherData] = useState<Array<Reading>>([]);
  
  const handleStationSelect = async (station:WeatherStation) => {
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 3, currentYear - 2,currentYear - 1, currentYear ];

    setSelectedStation(station);
    const promises = years.map(year =>
      fetch(`https://insightdev.kwiqly.com/weather/${year}/${station.USAF}/${station.WBAN}`)
        .then(response => {
          if (response.ok) { // Check if the status code is 200
            return response.text();
          } else {
            return ''
          }
        })
    );

    try {
      const dataArrays = await Promise.all(promises);
      const aggregatedData = aggregateData(dataArrays);

        // @ts-ignore
      setWeatherData(aggregatedData);
      console.log(aggregatedData)
      const data: RawDataItem[] = readings.map(reading => {
        const matchingWeatherReading = aggregatedData.find(day => day.ts === reading.ts);
        
        return {    
          ts: reading.ts,
          
          oat: matchingWeatherReading ? matchingWeatherReading.rate : 0,
          rate: reading.rate,
          readings: reading.values.join(','),
          exclude: false,
          // Convert JavaScript day (0-6, Sunday=0) to ISO day (1-7, Monday=1)
          mode: (new Date(reading.ts).getDay() + 6) % 7 + 1,
          cause: 'Clean'
        };
      });
     
      setCombinedData(data.filter(day=>day.oat));
      console.log(data)
      processMeterData(data).then(data => {
        console.log(data);
       setModelWithEvents(data.modelWithEvents);
        setEventFeatures(data.eventFeatures);
        setPentiledEventProfile(data.pentiledEventProfile);
        setPentiledProfile(data.pentiledProfile);
        setEventSummary(data.eventSummary);
        setModeledData(data.modeledData);
      });
        
    } catch (error) {
      console.error("Failed to fetch weather data:", error);
    }
  };
  const handleLocationSelect = (location: React.SetStateAction<any>) => {
    setSelectedLocation(location);
  };


  const handleCsvDataLoaded = (data: Reading[]): void => {
    setReadings(data);
   };

  const handleOccupancyChange = (data: React.SetStateAction<{}>) => {
    setOccupancyData(data);
  };

  const onNext = () => {
    if (activeIndex < items.length - 1) {
      transitionToStep(activeIndex + 1);
    }
  }

  const onBack = () => {
    if (activeIndex > 0) {
      transitionToStep(activeIndex - 1);
    }
  }
  const appData= {location:selectedLocation, data:csvData, occupancy:occupancyData}
  const getStepContent = (index: number) => {
    switch (index) {
      case 0:
        return <div>
         <DataUpload onDataLoaded={handleCsvDataLoaded}/>
         {readings.length > 0 && (
         <TimeChart 
            excludedReadings={[]}
            cleanedReadings={readings.map(reading => {
              return {    
                ts: reading.ts,
                oat: 0,
                rate: reading.rate,
                readings: reading.values.join(','),
                exclude: false,
                // Convert JavaScript day (0-6, Sunday=0) to ISO day (1-7, Monday=1)
                mode: (new Date(reading.ts).getDay() + 6) % 7 + 1,
                cause: 'Clean'
              };
            })}
            data={modeledData} 
            width={chartDimensions.width} 
            height={chartDimensions.height}
            xDomain={[new Date('2023-01-01'), new Date('2024-12-31')]}
          />)}
         </div>;
      case 1:
          return <MapSelection onLocationSelected={handleLocationSelect} onNext={()=>{}}  onStationSelected={handleStationSelect}/>;
      case 2:
        return   <div className="diagramm-container">
       
        {modeledData.length > 0 && (
          <div className="diagramm card">
            <h4>Model & actual Power</h4>
            <TimeChart 
              excludedReadings={[]}
              cleanedReadings={combinedData}
              data={modeledData} 
              width={chartDimensions.width} 
              height={chartDimensions.height}
              xDomain={[new Date('2023-01-01'), new Date('2024-12-31')]}
            />
          </div>
        )}
        <div className="diagramm card">
          <h4>Cumulative Deviation kWh</h4>
          <EventHistoryChart 
            eventList={eventSummary || []} 
            data={modelWithEvents} 
            width={chartDimensions.width} 
            height={chartDimensions.height}
            xDomain={[new Date('2023-01-01'), new Date('2024-12-31')]} />
        </div>

        <div className="diagramm card">
          <h4>OAT Chart</h4>
          <OATChart 
            rawData={combinedData} 
            data={modelWithEvents} 
            width={chartDimensions.width} 
            height={chartDimensions.height} 
          />
        </div>
        <div className="diagramm card">
          <h4>Event & Prior Year By Time of Week and Temperature</h4>
          <NtileEventProfileChart 
            pentiledModel={pentiledProfile||[]} 
            pentiledEventModel={pentiledEventProfile||[]} 
            width={chartDimensions.width} 
            height={chartDimensions.height} 
          />
        </div>
       
      </div>;
     
      default:
        return <div>Unknown Step</div>;
    }
  };

  const transitionToStep=(index: number)=> {
    switch (index) {
      case 0:
        setActiveIndex(0);break
      case 1:
       
          setActiveIndex(1);
       
        break
      case 2:
       
          setActiveIndex(2);
       
        break
      case 3:
        setActiveIndex(3);break
      default:
        return
    }
  }
  return (
    <React.Fragment>
 

    <div className={'stepContainer'}>
      <div className={'stepHeader'}>
       <UserInfo user={user} onUserChange={setUser} />
      </div>
      <Steps model={items} activeIndex={activeIndex} onSelect={(e) => transitionToStep(e.index)} readOnly={false}/>
      <div className={'step'}>
        
        
        {getStepContent(activeIndex)}
         </div>
    </div>
   </React.Fragment>
  );
};

export default StepComponent;
