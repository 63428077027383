/**
 * Collection of mathematical utility functions for data processing and clustering
 */

/**
 * Calculate the mean of a number array
 * @param series Array of numbers
 * @returns The mean value
 */
export function mean(series: number[]): number {
  if (!series || series.length === 0) return 0;
  return series.reduce((sum, val) => sum + val, 0) / series.length;
}

/**
 * Calculate the standard deviation of a number array
 * @param series Array of numbers
 * @returns The standard deviation
 */
export function standardDeviation(series: number[]): number {
  if (!series || series.length === 0) return 0;
  const avg = mean(series);
  return Math.sqrt(series.reduce((sum, val) => sum + (val - avg) ** 2, 0) / series.length);
}

/**
 * Calculate the minimum to maximum ratio
 * @param series Array of numbers
 * @returns The min/max ratio (min divided by max)
 */
export function minMaxDiff(series: number[]): number {
  if (!series || series.length === 0) return 0;
  const min = Math.min(...series);
  const max = Math.max(...series);
  if (min === 0 || max === 0) return 0;
  return min / max;
}

/**
 * Calculate the ratio of peak to mean value
 * @param series Array of numbers
 * @returns The peak to mean ratio
 */
export function peakToMeanRatio(series: number[]): number {
  if (!series || series.length === 0) return 0;
  const avgValue = mean(series);
  return Math.max(...series) / (avgValue || 1);
}

/**
 * Calculate autocorrelation of a time series at a given lag
 * @param series Array of numbers
 * @param lag Lag value for autocorrelation
 * @returns Autocorrelation value
 */
export function autocorrelation(series: number[], lag: number): number {
  if (!series || series.length === 0 || lag >= series.length) return 0;
  const avg = mean(series);
  const numerator = series.slice(0, -lag).reduce((sum, val, i) => sum + (val - avg) * (series[i + lag] - avg), 0);
  const denominator = series.reduce((sum, val) => sum + (val - avg) ** 2, 0);
  return denominator === 0 ? 0 : numerator / denominator;
}

/**
 * Calculate the entropy of a series
 * @param series Array of numbers
 * @returns Entropy value
 */
export function entropy(series: number[]): number {
  if (!series || series.length === 0) return 0;
  const sum = series.reduce((acc, val) => acc + Math.max(0, val), 0);
  if (sum === 0) return 0;
  
  const probabilities = series.map(val => Math.max(0, val) / sum);
  return -probabilities.reduce((acc, p) => (p > 0 ? acc + p * Math.log2(p) : acc), 0);
}

/**
 * Calculate Fourier transform magnitudes
 * @param series Array of numbers
 * @returns Array of FFT magnitude values
 */
export function fourierTransformMagnitude(series: number[]): number[] {
  if (!series || series.length === 0) return [];
  
  const N = series.length;
  const magnitudes: number[] = [];
  
  for (let k = 0; k < N; k++) {
    let real = 0, imag = 0;
    for (let n = 0; n < N; n++) {
      const angle = (2 * Math.PI * k * n) / N;
      real += series[n] * Math.cos(angle);
      imag -= series[n] * Math.sin(angle);
    }
    magnitudes.push(Math.sqrt(real ** 2 + imag ** 2));
  }
  
  return magnitudes;
}

/**
 * Normalize array values to range [0,1]
 * @param data Array of numbers
 * @returns Normalized array
 */
export function normalize(data: number[]): number[] {
  if (!data || data.length === 0) return [];
  
  const min = Math.min(...data);
  const max = Math.max(...data);
  const range = max - min;
  
  return data.map(x => (range === 0 ? 0 : (x - min) / range));
}

/**
 * Standardize array to have mean=0 and std=1
 * @param data Array of numbers
 * @returns Standardized array
 */
export function standardize(data: number[]): number[] {
  if (!data || data.length === 0) return [];
  
  const meanValue = mean(data);
  const std = Math.sqrt(data.reduce((acc, val) => acc + Math.pow(val - meanValue, 2), 0) / data.length);
  
  return std === 0 ? data.map(() => 0) : data.map(val => (val - meanValue) / std);
}

/**
 * Count the number of unique values in an array
 * @param arr Array of any type
 * @returns Number of unique values
 */
export function countUniqueValues<T>(arr: T[]): number {
  if (!arr || arr.length === 0) return 0;
  return new Set(arr).size;
}

/**
 * Calculate Euclidean distance between two vectors
 * @param a First vector
 * @param b Second vector
 * @returns Euclidean distance
 */
export function euclideanDistance(a: number[], b: number[]): number {
  if (!a || !b || a.length !== b.length) {
    return Infinity;
  }
  return Math.sqrt(a.reduce((sum, val, i) => sum + Math.pow(val - b[i], 2), 0));
}
