import React, { useState } from 'react';
import { FEATURE_NAMES } from '../services/clusterService';

// Define a type for the feature keys
type FeatureKey = keyof typeof FEATURE_NAMES;

interface FeatureSelectorProps {
  selectedFeatures: FeatureKey[];
  onFeaturesChange: (features: FeatureKey[]) => void;
}

const FeatureSelector: React.FC<FeatureSelectorProps> = ({ 
  selectedFeatures, 
  onFeaturesChange 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleFeature = (feature: FeatureKey) => {
    if (selectedFeatures.includes(feature)) {
      // Remove feature if already selected
      onFeaturesChange(selectedFeatures.filter(f => f !== feature));
    } else {
      // Add feature if not selected
      onFeaturesChange([...selectedFeatures, feature]);
    }
  };
  
  const selectAll = () => {
    onFeaturesChange(Object.keys(FEATURE_NAMES) as FeatureKey[]);
  };
  
  const selectNone = () => {
    onFeaturesChange([]);
  };
  
  return (
    <div style={{ position: 'relative' }}>
      <button 
        onClick={() => setIsOpen(!isOpen)}
        style={{
          padding: '8px 12px',
          backgroundColor: '#f0f0f0',
          border: '1px solid #ccc',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}
      >
        <span>Features ({selectedFeatures.length})</span>
        <span>{isOpen ? '▲' : '▼'}</span>
      </button>
      
      {isOpen && (
        <div style={{
          position: 'absolute',
          top: '100%',
          left: 0,
          zIndex: 10,
          backgroundColor: 'white',
          border: '1px solid #ccc',
          borderRadius: '4px',
          padding: '12px',
          width: '300px',
          maxHeight: '400px',
          overflowY: 'auto',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
        }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            marginBottom: '8px',
            borderBottom: '1px solid #eee',
            paddingBottom: '8px'
          }}>
            <button 
              onClick={selectAll}
              style={{
                padding: '4px 8px',
                backgroundColor: '#f0f0f0',
                border: '1px solid #ccc',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '12px'
              }}
            >
              Select All
            </button>
            <button 
              onClick={selectNone}
              style={{
                padding: '4px 8px',
                backgroundColor: '#f0f0f0',
                border: '1px solid #ccc',
                borderRadius: '4px',
                cursor: 'pointer',
                fontSize: '12px'
              }}
            >
              Select None
            </button>
          </div>
          
          <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '8px' }}>
            {Object.entries(FEATURE_NAMES).map(([key, name]) => (
              <div 
                key={key}
                style={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: '8px',
                  padding: '4px',
                  borderRadius: '4px',
                  backgroundColor: selectedFeatures.includes(key as FeatureKey) ? '#e6f7ff' : 'transparent',
                  cursor: 'pointer'
                }}
                onClick={() => toggleFeature(key as FeatureKey)}
              >
                <input 
                  type="checkbox" 
                  checked={selectedFeatures.includes(key as FeatureKey)}
                  onChange={() => {}} // Handled by div click
                  id={`feature-${key}`}
                />
                <label 
                  htmlFor={`feature-${key}`}
                  style={{ 
                    cursor: 'pointer',
                    fontSize: '14px',
                    userSelect: 'none'
                  }}
                >
                  {name}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeatureSelector;
